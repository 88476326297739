import { useGetTaskChangesQuery } from '../../../../api/task-api';
import { MONTHS } from '../../../../common/constants';
import type {
  DeleteNote,
  EditNote
} from '../../../../common/types/rtk-triggers/note';
import { ActivityItem } from '../../../../components/activity-item';
import { LoaderSmall } from '../../../../components/loader-small';
import { Note } from '../../../../components/notes/note';
import { hasOwnProperty } from '../../../../helpers/has-own-property';
import type { NoteActivity } from '../../../../models/activity';
import type { Change } from '../../../../models/changes';
import type { Note as INote } from '../../../../models/note';
import {
  ActivityListStyled,
  ChamgeMessage,
  GroupByMonth,
  GroupByMonthTitle,
  Loader
} from './style';

interface TaskChangesProps {
  id: string | number;
  editNote: EditNote;
  deleteNote: DeleteNote;
}

export const TaskChanges = ({ id, editNote, deleteNote }: TaskChangesProps) => {
  const { data, isLoading: isLoading } = useGetTaskChangesQuery(
    { id },
    { skip: !id }
  );

  const activitiesByMonth = () => {
    let tempArr: {
      title: string;
      list: (Change | NoteActivity)[];
    }[] = [];
    data?.activities?.forEach(([date, item]) => {
      const currentTitle = `${MONTHS[new Date(date).getMonth()]} ${new Date(
        date
      ).getFullYear()}`;

      const currentObj = tempArr.find((obj) => obj.title === currentTitle);
      if (!currentObj) {
        tempArr = [...tempArr, { title: currentTitle, list: [item] }];
      } else {
        tempArr = tempArr.map((el) =>
          el.title === currentTitle ? { ...el, list: [...el.list, item] } : el
        );
      }
    });

    return tempArr;
  };

  return (
    <ActivityListStyled>
      {isLoading && (
        <Loader>
          <LoaderSmall />
        </Loader>
      )}
      {activitiesByMonth().map((item, i) => (
        <GroupByMonth key={i}>
          <GroupByMonthTitle>{item.title}</GroupByMonthTitle>
          {item.list.map((activityItem, j) => {
            if (hasOwnProperty(activityItem, 'note')) {
              return (
                <Note
                  editNote={editNote}
                  deleteNote={deleteNote}
                  key={j}
                  note={activityItem.note as INote}
                />
              );
            }

            return (
              <ActivityItem
                key={j}
                title={<span>{activityItem.name}</span>}
                createdAt={activityItem.date}
              >
                <ChamgeMessage>{activityItem.message}</ChamgeMessage>
              </ActivityItem>
            );
          })}
        </GroupByMonth>
      ))}
    </ActivityListStyled>
  );
};
