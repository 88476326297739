import styled from 'styled-components';
import { TASK_STATUSES } from '../../common/constants';

export const TableSelectWrapper = styled.div`
  position: relative;
  height: 100%;
`;

export const TableSelectStyled = styled.div<{
  $isOpen?: boolean;
  $disabled?: boolean;
  $withoutBg?: boolean;
  $color?: string;
  $width?: string;
}>`
  padding: 0 10px;
  border-radius: 8px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${(p) => p.theme.colors.white};
  width: ${(p) => p.$width || '100%'};
  background: ${(p) => p.$color || TASK_STATUSES[0].restFields?.color};
  font-size: 12px;
  font-weight: 400;
  line-height: 140%;
  cursor: pointer;
  svg {
    min-width: 16px;
    transform: ${(p) => (p.$isOpen ? 'rotate(180deg)' : 'rotate(0)')};
    path {
      fill: ${(p) => p.theme.colors.white};
      stroke: ${(p) => p.theme.colors.white};
    }
  }
  pointer-events: ${(p) => (p.$disabled ? 'none' : 'unset')};
`;

export const TableSelectOptions = styled.div<{ $openTop?: boolean }>`
  padding: 8px 0;
  position: absolute;
  max-height: 300px;
  overflow: auto;
  left: 0;
  top: ${(p) => (p.$openTop ? 'unset' : 'calc(100% - 8px)')};
  bottom: ${(p) => (p.$openTop ? 'calc(100% - 16px)' : 'unset')};
  width: 100%;
  border-radius: 9px;
  border: 1px solid ${(p) => p.theme.colors.border};
  background: ${(p) => p.theme.colors.white};
  box-shadow: 4px 5px 9px 0px rgba(63, 81, 126, 0.08),
    -3px -3px 9px 0px rgba(63, 81, 126, 0.06);
  z-index: 1;
`;

export const OptionsItem = styled.div`
  padding: 10px;
  color: ${(p) => p.theme.colors.black};
  font-size: 12px;
  font-weight: 400;
  line-height: 140%;
  background: ${(p) => p.theme.colors.white};
  &:hover {
    color: ${(p) => p.theme.colors.white};
    background: ${(p) => p.theme.colors.primary};
  }
  cursor: pointer;
`;
