import styled, { keyframes } from 'styled-components';
import { centerFlex, scrollBar } from '../../style/helpers';

interface SelectHeaderProps {
  isActive?: boolean;
  disabled?: boolean;
  size?: {
    width?: string;
    height?: string;
  };
}

export const SelectWrapper = styled.div<{ mt?: string; mb?: string }>`
  position: relative;
  margin-top: ${(p) => p.mt || 0};
  margin-bottom: ${(p) => p.mb || 0};
  /* width: 100%; */
`;

export const SelectHeader = styled.div<SelectHeaderProps>`
  padding: 5px 12px;
  height: ${(p) => p.size?.height || '36px'};
  width: ${(p) => p.size?.width || '100%'};
  padding-right: 16px;
  position: relative;
  border: 1px solid
    ${(p) =>
      p.isActive ? p.theme.colors.blue.primary : p.theme.colors.grey.input};
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
  > svg {
    min-width: 8px;
    transform: ${(p) => (p.isActive ? 'rotate(180deg)' : 'rotate(0)')};
    path {
      stroke: ${(p) =>
        p.isActive ? p.theme.colors.blue.primary : p.theme.colors.grey.light};
    }
  }
  user-select: none;
`;

export const SelectHeaderTitle = styled.div<{ fz?: string }>`
  width: calc(100% - 10px);
  font-weight: 400;
  font-size: ${(p) => p.fz || '14px'};
  line-height: 140%;
  color: ${(p) => p.theme.colors.blue.dark};
  span {
    color: ${(p) => p.theme.colors.grey.light};
  }
`;

export const SelectOptions = styled.div<{
  isStatic?: boolean;
  openUp?: boolean;
  customWidth?: string;
}>`
  position: ${(p) => (p.isStatic ? 'static' : 'absolute')};
  margin-top: ${(p) => (p.isStatic ? '6px' : '0')};
  left: 0;
  width: ${(p) => p.customWidth || '100%'};
  top: ${(p) => (p.openUp ? 'unset' : 'calc(100% + 6px)')};
  bottom: ${(p) => (p.openUp ? 'calc(100% + 6px)' : 'unset')};
  z-index: 11;
  border: 1px solid #e7e9f6;
  background: ${(p) => p.theme.colors.white};
  box-shadow: -3px -3px 9px rgba(63, 81, 126, 0.06),
    4px 5px 9px rgba(63, 81, 126, 0.08);
  border-radius: 6px;
`;

export const OptionsScroolWrapper = styled.div`
  max-height: 200px;
  border-radius: 6px;
  background: ${(p) => p.theme.colors.white};
  ${scrollBar}
`;

export const SelectOptionItem = styled.div<{ openUp?: boolean }>`
  padding: ${(p) => (p.openUp ? '7px 12px' : '9px 24px')};
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: ${(p) => p.theme.colors.blue.dark};
  background-color: ${(p) => p.theme.colors.white};
  transition: background-color 0.2s;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  :hover {
    background-color: ${(p) => p.theme.colors.blue.bg};
    transition: background-color 0.2s;
  }
`;

export const SearchStyled = styled.div`
  position: relative;
  padding: 12px;
`;

export const SearchInput = styled.input`
  width: 100%;
  display: block;
  padding: 8px 34px;
  border: 1px solid ${(p) => p.theme.colors.grey.table};
  border-radius: 6px;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: ${(p) => p.theme.colors.blue.dark};
  ::placeholder {
    color: ${(p) => p.theme.colors.grey.light};
  }
`;

export const SearchIcon = styled.div`
  position: absolute;
  left: 24px;
  top: calc(50% - 7px);
  width: 14px;
  height: 14px;
  ${centerFlex}
  svg {
    max-width: 14px;
  }
`;

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const LoaderStyled = styled.span<{ right?: string }>`
  position: absolute;
  right: ${(p) => p.right || '24px'};
  top: calc(50% - 7px);
  width: 14px;
  height: 14px;
  border: 2px dotted ${(p) => p.theme.colors.blue.primary};
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: ${rotate} 2s linear infinite;
  z-index: 10;
`;

export const MultipleHeader = styled.div`
  overflow: hidden;
  span {
    padding-top: 2px;
    color: ${(p) => p.theme.colors.grey.light};
    height: 100%;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
  }
`;

export const OverflowContainer = styled.div`
  display: flex;
  gap: 8px;
  /* width: 1000px; */
`;
