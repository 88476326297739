import { removeCookie } from 'typescript-cookie';
// import { LogoSvg } from '../svg/logo-svg';
import { ExitSvg } from '../svg/sidebar/exit-svg';
// import { SettingsSvg } from '../svg/sidebar/settings-svg';
import { useAppDispatch, useTypedSelector } from '../../api/store';
import { StorageKey } from '../../common/enums/storage/storage-key.enum';
import { logOut } from '../../api/auth-slice';
import { useGetUserMeQuery } from '../../api/user-api';
import { ApiTag } from '../../common/enums/http/api-tag.enum';
import { SidebarAngleSvg } from '../svg/angle-sidebar-svg';
import { toggleMenu } from '../../api/page-data-slice';
import { checkIsManager } from '../../helpers/is-manager';
import { managerMenuList, menuList } from './data';
import { SidebarFooterItem } from './sidebar-footer-item';
import { SidebarMenuItem } from './sidebar-menu-item';
import { Menu, SidebarStyled, SidebarToggleBtn } from './style';

export const Sidebar = () => {
  const { data: userMe } = useGetUserMeQuery('');
  const dispatch = useAppDispatch();
  const { menuIsOpen } = useTypedSelector((state) => state['page-data']);

  const handleLogOut = () => {
    dispatch(logOut());
    localStorage.removeItem(StorageKey.ACCESS_TOKEN);
    removeCookie(StorageKey.REFRESH_TOKEN, { path: '/' });
  };

  const handleToggle = () => {
    dispatch(toggleMenu());
  };

  if (!userMe) {
    return null;
  }

  const menu = userMe.is_superuser
    ? menuList
    : checkIsManager(userMe)
    ? managerMenuList
    : menuList;

  return (
    <SidebarStyled isOpen={menuIsOpen}>
      <SidebarToggleBtn isOpen={menuIsOpen} onClick={handleToggle}>
        <SidebarAngleSvg />
      </SidebarToggleBtn>
      {/* <Logo isOpen={menuIsOpen}>
        <LogoSvg />
      </Logo> */}
      <Menu isOpen={menuIsOpen}>
        {menu
          .filter((item) => {
            if (item.tag === ApiTag.USERS) {
              if (userMe.role?.view_user || userMe.is_superuser) {
                return true;
              }

              return false;
            }
            if (item.tag === ApiTag.CONTACTS) {
              if (userMe.role?.view_contact || userMe.is_superuser) {
                return true;
              }

              return false;
            }
            if (item.tag === ApiTag.PARTNERS) {
              if (userMe.role?.view_partner || userMe.is_superuser) {
                return true;
              }

              return false;
            }
            if (item.tag === ApiTag.PROJECTS) {
              if (userMe.role?.view_project || userMe.is_superuser) {
                return true;
              }

              return false;
            }
            if (item.tag === ApiTag.EVENTS) {
              if (userMe.role?.view_event && !userMe.role?.view_project) {
                return true;
              }

              return false;
            }

            return true;
          })
          .map((item, i) => (
            <SidebarMenuItem menuIsOpen={menuIsOpen} key={i} {...item} />
          ))}
      </Menu>
      <div>
        {/* <SidebarFooterItem title="Налаштування" icon={SettingsSvg} /> */}
        <SidebarFooterItem
          title="Вихід"
          icon={ExitSvg}
          onClick={handleLogOut}
          menuIsOpen={menuIsOpen}
        />
      </div>
    </SidebarStyled>
  );
};
