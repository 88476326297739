import type { OptionItem } from '../common/types/option-item';
import type { UserMe } from '../models/user';
import { checkIsManager } from './is-manager';

const defaultFields = [
  'id',
  'first_name',
  'last_name',
  'patronymic',
  'full_name',
  'phone',
  'current_steps',
  'created_at'
];

export const isFieldAllowed = (
  field: string,
  userMe: UserMe | undefined,
  subFields?: OptionItem[]
) => {
  if (!userMe) {
    return false;
  }

  if (
    userMe.is_superuser ||
    !checkIsManager(userMe) ||
    subFields ||
    defaultFields.includes(field)
  ) {
    return true;
  }
  const allowedFields = userMe.role
    ? Array.from(
        new Set(
          userMe.role.field_permissions
            .filter((item) =>
              userMe.projects.some((project) => project.id === item.project)
            )
            .map((item) => item.name)
        )
      )
    : [];

  return allowedFields.includes(field);
};

export const isFieldInProject = (
  field: string,
  allowedFields: string[] | undefined
) => {
  if (!allowedFields) {
    return true;
  }

  return allowedFields.includes(field) || defaultFields.includes(field);
};

export const isFieldAllowedForm = (
  field: string,
  allowedFields: string[] | undefined,
  userMe: UserMe | undefined,
  isEdit?: boolean
) => {
  if (!userMe?.is_superuser && !allowedFields) {
    return true;
  }

  return isEdit
    ? isFieldInProject(field, allowedFields)
    : isFieldAllowed(field, userMe);
};
