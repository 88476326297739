import type { Dispatch, SetStateAction } from 'react';
import { useState } from 'react';
import { Popup } from '../../../../components/popup';
import { ProjectForm } from '../../project-form';
import type { OptionItem } from '../../../../common/types/option-item';
import type { Project } from '../../../../models/project';
import { useEditProjectMutation } from '../../../../api/projects-api';
import { useNotification } from '../../../../hooks/use-notification';
import { getApiError } from '../../../../helpers/get-api-error';

interface EditProjectProps {
  project: Project;
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
}

export const EditProject = ({ project, show, setShow }: EditProjectProps) => {
  const notification = useNotification();
  const [editProject] = useEditProjectMutation();
  const [projectName, setProjectName] = useState(project.name);
  const [description, setDescription] = useState(project.description);
  const [startDate, setStartDate] = useState(
    project.start_date?.split('-').reverse().join('/') || ''
  );
  const [endDate, setEndDate] = useState(
    project.end_date?.split('-').reverse().join('/') || ''
  );
  const [respManager, setRespManager] = useState<OptionItem>({
    id: project.chief_manager?.id || '',
    title: `${project.chief_manager?.last_name || ''} ${
      project.chief_manager?.first_name || ''
    } ${project.chief_manager?.patronymic || ''}`
  });
  const [managers, setManagers] = useState<OptionItem[]>(
    project.managers.map((item) => ({
      id: item.id,
      title: `${item?.last_name || ''} ${item?.first_name || ''} ${
        item?.patronymic || ''
      }`
    }))
  );

  const handleEdit = () => {
    if (!respManager.id) {
      notification({
        type: 'error',
        message: 'Вкажіть керівника проєкту',
        title: 'Помилка'
      });

      return;
    }
    if (!managers.filter((item) => item.id).length) {
      notification({
        type: 'error',
        message: 'Вкажіть хоча б одного менеджера',
        title: 'Помилка'
      });

      return;
    }
    editProject({
      id: project.id,
      body: {
        name: projectName,
        description,
        start_date: startDate ? startDate.split('/').reverse().join('-') : null,
        end_date: endDate ? endDate.split('/').reverse().join('-') : null,
        chief_manager: respManager.id,
        managers: managers.map((item) => item.id)
      }
    })
      .unwrap()
      .then(() => {
        notification({
          type: 'success',
          message: 'Ви успішно відредагували проєкт',
          title: 'Проєкт відредаговано'
        });
        setShow(false);
      })
      .catch((err) => {
        notification({
          type: 'error',
          message: getApiError(err),
          title: 'Помилка'
        });
      });
  };

  return (
    <Popup title="Редагувати проєкт" show={show} setShow={setShow}>
      <ProjectForm
        projectName={projectName}
        setProjectName={setProjectName}
        setShow={setShow}
        description={description}
        setDescription={setDescription}
        startDate={startDate}
        setStartDate={setStartDate}
        respManager={respManager}
        setRespManager={setRespManager}
        endDate={endDate}
        setEndDate={setEndDate}
        managers={managers}
        setManagers={setManagers}
        onSubmit={handleEdit}
        btnPrimaryTitle="Зберегти"
      />
    </Popup>
  );
};
