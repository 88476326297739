import type { DefaultTheme } from 'styled-components';

export const theme: DefaultTheme = {
  colors: {
    primary: '#3673B2',
    primaryHover: '#5295DA',
    primaryLight: '#FFFBE7',
    white: '#FFFFFF',
    black: '#313654',
    error: '#FF3838',
    slate: '#455468',
    slateGrey: '#D7DFE9',
    dark: '#49516f',
    success: '#55D178',
    avatar: '#C5E2D1',
    card: { bg: '#F6F7FA' },
    status: {
      active: '#11A75C',
      activeLight: 'rgba(29, 180, 105, 0.12)',
      noActive: '#E5615C',
      noActiveLight: 'rgba(229, 97, 92, 0.12)'
    },
    btn: {
      disabledText: '#96AAC9',
      disabledBg: '#F2F2F5'
    },
    popup: {
      bg: 'rgba(41, 48, 74, .7)',
      border: '#E8E8EC'
    },
    violet: {
      dark: '#3D344E'
    },
    grey: {
      primary: '#494E5B',
      bg: '#F3F4F6',
      table: '#E7E9F6',
      input: '#D2D6ED',
      light: '#9DABC0',
      seventy: '#7E92A2',
      secondary: '#676E79'
    },
    blue: {
      primary: '#306FE3',
      bg: ' #F3F8FC',
      dark: '#171C3D',
      hover: '#FAFBFD',
      hoverPrimary: '#215ECF'
    },
    rolePicker: [
      '#EA3939',
      '#F5B43F',
      '#33EA23',
      '#4DEDC7',
      '#2EE7E7',
      '#279AED',
      '#557CD1',
      '#802FD1',
      '#DD3A92',
      '#29304A'
    ],
    border: '#D9D9D9',
    steps: ['#E5A090', '#F5B43F', '#E8C341', '#4EBAF9'],
    opacitySteps: ['#FFF1EE', '#FFF9ED', '#FFF9E4', '#F1FAFF'],
    processed: '#6FD4E3'
  },
  breakpoints: {
    xxl: '1600px',
    xl: '1200px',
    md: '992px'
  }
};
