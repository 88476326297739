import type { Dispatch, SetStateAction } from 'react';
import { FlexContainer } from '../flex-container';
import { Search } from '../search';
import { HeaderNotif } from './header-notif';
import { HeaderUser } from './header-user';
import { HeaderStyled } from './style';

interface HeaderProps {
  showResults: boolean;
  setShowResults: Dispatch<SetStateAction<boolean>>;
  withOutGlobalSearch?: boolean;
}

export const Header = ({
  setShowResults,
  showResults,
  withOutGlobalSearch
}: HeaderProps) => (
  <HeaderStyled>
    <Search
      showResults={showResults}
      setShowResults={setShowResults}
      placeholder="Пошук по контактам"
      withOutGlobalSearch={withOutGlobalSearch}
    />
    <FlexContainer>
      <HeaderNotif />
      <HeaderUser />
    </FlexContainer>
  </HeaderStyled>
);
