import styled from 'styled-components';
import { scrollBar } from '../../../../style/helpers';

export const NotifContent = styled.div`
  padding: 20px;
  padding-top: 0;
  position: fixed;
  top: 58px;
  right: 52px;
  border-radius: 9px;
  border: 1px solid ${(p) => p.theme.colors.border};
  z-index: 10;
  max-height: 320px;
  max-width: 398px;
  width: 100%;
  ${scrollBar}
  background: #fff;
  @media (max-width: ${(p) => p.theme.breakpoints.md}) {
    right: 16px;
    width: calc(100% - 32px);
    top: 46px;
  }
`;

export const NotifContentHeader = styled.div`
  padding: 20px;
  padding-bottom: 0;
  text-align: center;
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const NotifCount = styled.div`
  color: #000;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  margin-right: 14px;
`;

export const NotifTitle = styled.div`
  padding: 12px 0;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: #000;
  font-size: 14px;
  font-weight: 600;
  gap: 12px;
  span {
    margin-top: 4px;
    font-size: 12px;
    font-weight: 400;
  }
  cursor: pointer;
  border-bottom: 1px solid ${(p) => p.theme.colors.border};
`;

export const Text = styled.p`
  margin-top: 8px;
  color: #000;
  font-size: 12px;
  font-weight: 400;
  line-height: 160%;
`;
