import type { Dispatch, FormEventHandler, SetStateAction } from 'react';
import { Input } from '../../../components/input';
import { InputDate } from '../../../components/input-date';
import { Select } from '../../../components/select';
import { TextArea } from '../../../components/text-area';
import type { OptionItem } from '../../../common/types/option-item';
import { EVENT_FORMAT } from '../../../common/constants';
import { IconContainer } from '../../../components/icon-container';
import { AddItem } from '../../../components/add-item/indes';
import { CloseEditSvg } from '../../../components/svg/close-edit-svg';
import { FormBtns } from '../../../components/form-btns';
import { useGetCurrentProjectQuery } from '../../../api/projects-api';
import type { Step } from '../../../models/event';
import {
  FormStyled,
  FormTitle,
  GroupTitle,
  InputGroup,
  InputsContainer,
  ManagerItem
} from './style';
import { mapManagers } from './helpers';

interface EventsFormProps {
  formTitle: string;
  name: string;
  setName: Dispatch<SetStateAction<string>>;
  description: string;
  setDescription: Dispatch<SetStateAction<string>>;
  format: OptionItem;
  setFormat: Dispatch<SetStateAction<OptionItem>>;
  region: string;
  setRegion: Dispatch<SetStateAction<string>>;
  startDate: string;
  setStartDate: Dispatch<SetStateAction<string>>;
  endDate: string;
  setEndDate: Dispatch<SetStateAction<string>>;
  budget: string;
  setBudget: Dispatch<SetStateAction<string>>;
  managers: OptionItem[];
  setManagers: Dispatch<SetStateAction<OptionItem[]>>;
  setShow: Dispatch<SetStateAction<boolean>>;
  submitTitle: string;
  cancelTitle: string;
  onSubmit: () => void;
  isEdit?: boolean;
  metaTags: string[];
  setMetaTags: Dispatch<SetStateAction<string[]>>;
  projectId: string;
  steps?: Step[];
  setSteps?: Dispatch<SetStateAction<Step[]>>;
}

export const EventsForm = ({
  formTitle,
  name,
  setName,
  description,
  setDescription,
  format,
  setFormat,
  region,
  setRegion,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  budget,
  setBudget,
  managers,
  setManagers,
  setShow,
  submitTitle,
  cancelTitle,
  onSubmit,
  isEdit,
  metaTags,
  setMetaTags,
  projectId,
  steps,
  setSteps
}: EventsFormProps) => {
  const { data: project } = useGetCurrentProjectQuery(
    { id: projectId },
    { skip: !projectId, refetchOnMountOrArgChange: true }
  );

  const handleDelete = (i: number) => {
    if (managers.length === 1) {
      setManagers([{ id: '', title: '' }]);
    } else {
      setManagers((state) => state.filter((_, j) => j !== i));
    }
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    onSubmit();
  };

  const handleChangeBudget = (value: string) => {
    if (isNaN(+value) && value !== '') {
      return;
    }
    setBudget(value);
  };

  return (
    <FormStyled onSubmit={handleSubmit}>
      {!isEdit && <FormTitle>{formTitle}</FormTitle>}
      <InputsContainer>
        <InputGroup>
          <Input
            label="Назва події"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <TextArea
            label="Опис події"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
          <Select
            label="Формат події"
            value={format}
            setValue={setFormat}
            options={EVENT_FORMAT}
          />
        </InputGroup>
        <InputGroup>
          <Input
            label="Регіон події"
            value={region}
            onChange={(e) => setRegion(e.target.value)}
            required
          />
          <InputDate
            label="Дата початку"
            value={startDate}
            setValue={setStartDate}
            required
          />
          <InputDate
            label="Дата завершення"
            value={endDate}
            setValue={setEndDate}
          />
          <Input
            label="Бали"
            value={budget}
            onChange={(e) => handleChangeBudget(e.target.value)}
            placeholder="Зазначте яка кількість балів"
          />
        </InputGroup>
      </InputsContainer>
      <GroupTitle>Менеджери події</GroupTitle>
      <InputsContainer>
        <InputGroup>
          {managers.map((item, i) => (
            <ManagerItem key={i}>
              <Select
                value={item}
                setValue={(value) =>
                  setManagers((state) =>
                    state.map((manager, j) => (i === j ? value : manager))
                  )
                }
                options={mapManagers(project?.managers, managers)}
              />
              {managers.length === 1 && !managers[0].id ? (
                ''
              ) : (
                <IconContainer m="0 0 11px 0" onClick={() => handleDelete(i)}>
                  <CloseEditSvg />
                </IconContainer>
              )}
            </ManagerItem>
          ))}
          {managers.filter((item) => item.id).length === managers.length &&
          mapManagers(project?.managers, managers).length ? (
            <AddItem
              onClick={() =>
                setManagers((state) => [...state, { id: '', title: '' }])
              }
              title="+ Додати менеджера"
            />
          ) : (
            ''
          )}
        </InputGroup>
      </InputsContainer>
      <GroupTitle>Метатеги події</GroupTitle>
      <InputsContainer>
        {metaTags.map((item, i) => (
          <Input
            key={i}
            value={item}
            onChange={(e) =>
              setMetaTags((state) =>
                state.map((tag, j) => (i == j ? e.target.value : tag))
              )
            }
            withBtns
            placeholder="Введіть назву "
            onClearInput={() =>
              setMetaTags((state) => state.map((tag, j) => (i == j ? '' : tag)))
            }
          />
        ))}
      </InputsContainer>
      {steps && setSteps && (
        <>
          <GroupTitle>Етапи події</GroupTitle>
          <InputsContainer>
            {steps.map((item, i) => (
              <Input
                label={
                  steps.length - 1 == i ? 'Останній крок' : `Крок ${i + 1}`
                }
                key={i}
                value={item.name}
                onChange={(e) =>
                  setSteps((state) =>
                    state.map((step, j) =>
                      i == j ? { ...step, name: e.target.value } : step
                    )
                  )
                }
                withBtns
                required
                placeholder="Введіть назву "
                onClearInput={() =>
                  setSteps((state) =>
                    state.map((step, j) =>
                      i == j ? { ...step, name: '' } : step
                    )
                  )
                }
              />
            ))}
          </InputsContainer>
        </>
      )}

      <FormBtns
        primaryTitle={submitTitle}
        secondaryTitle={cancelTitle}
        onCancel={() => setShow(false)}
      />
    </FormStyled>
  );
};
