import { LoaderSmallStyled, LoaderWrapper } from './style';

interface LoaderSmallProps {
  ac?: boolean;
  mt?: string;
}

const LoaderSmall = ({ ac, mt }: LoaderSmallProps) => (
  <LoaderWrapper mt={mt} ac={ac}>
    <LoaderSmallStyled />
  </LoaderWrapper>
);

export { LoaderSmall };
