import styled from 'styled-components';
import { centerFlex } from '../../style/helpers';

export const ExportImportStyled = styled.div`
  position: relative;
  margin-right: 20px;
`;

export const ExportImportHeader = styled.div`
  height: 40px;
  background: ${(p) => p.theme.colors.white};
  border: 1px solid ${(p) => p.theme.colors.grey.input};
  border-radius: 6px;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const Icon = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 10px;
  ${centerFlex}
`;

export const Angle = styled.div<{ isOpen?: boolean }>`
  svg {
    transform: ${(p) => (p.isOpen ? 'rotate(180deg)' : 'rotate(0)')};
  }
`;

export const Options = styled.div<{ show: boolean }>`
  display: ${(p) => (p.show ? 'block' : 'none')};
  padding: 8px 0;
  position: absolute;
  left: 0;
  width: 100%;
  min-width: 184px;
  top: calc(100% + 6px);
  border: 1px solid #e7e9f6;
  box-shadow: -3px -3px 9px rgba(63, 81, 126, 0.06),
    4px 5px 9px rgba(63, 81, 126, 0.08);
  border-radius: 9px;
  z-index: 2;
  background: ${(p) => p.theme.colors.white};
`;

export const OptionItem = styled.div<{ openUp?: boolean }>`
  input {
    display: none;
  }
  padding: 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: ${(p) => p.theme.colors.blue.dark};
  background-color: ${(p) => p.theme.colors.white};
  transition: background-color 0.2s;
  cursor: pointer;
  display: flex;
  align-items: center;
  :hover {
    background-color: ${(p) => p.theme.colors.blue.bg};
    transition: background-color 0.2s;
  }
`;

export const ImportName = styled.div`
  padding: 20px 0;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: ${(p) => p.theme.colors.black};
  svg {
    margin-right: 20px;
  }
`;

export const PopupBtns = styled.div`
  display: flex;
  padding: 20px 0;
  justify-content: flex-end;
  gap: 20px;
  border-top: 1px solid ${(p) => p.theme.colors.popup.border};
`;
