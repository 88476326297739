import { Navigate } from 'react-router-dom';
import { useGetUserMeQuery } from '../../../api/user-api';
import { AppRoute } from '../../../common/enums/app/app-route.enum';
import { UsersAccessStyled } from './style';
import { ActionAccess } from './action-access';

export const UsersAccess = () => {
  const { data: userMe } = useGetUserMeQuery('');

  if (!userMe) {
    return null;
  }

  if (
    (!userMe.role?.view_role || !userMe.role?.change_role) &&
    !userMe?.is_superuser
  ) {
    return <Navigate to={{ pathname: AppRoute.USERS }} />;
  }

  return (
    <UsersAccessStyled>
      <ActionAccess />
    </UsersAccessStyled>
  );
};
