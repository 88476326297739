import type { FC } from 'react';
import { AppRoute } from '../../common/enums/app/app-route.enum';
import { ApiTag } from '../../common/enums/http/api-tag.enum';
import { ContactsSvg } from '../svg/sidebar/contacts-svg';
import { DashboardSvg } from '../svg/sidebar/dashboard-svg';
import { EventsSvg } from '../svg/sidebar/events-svg';
import { ProjectsSvg } from '../svg/sidebar/projects-svg';
// import { ReportsSvg } from '../svg/sidebar/reports-svg';
import { UsersSvg } from '../svg/sidebar/users-svg';
import { PartnerSvg } from '../svg/sidebar/partner-svg';
import { ActualProjectsSvg } from '../svg/sidebar/actual-projects-svg';
import { ArchProjectsSvg } from '../svg/sidebar/arch-projects-svg';
import { TaskSvg } from '../svg/sidebar/task-svg';

interface Menu {
  title: string;
  icon: FC;
  path?: AppRoute;
  tag?: ApiTag;
  iconStrokeColored?: boolean;
  list?: {
    title: string;
    icon: FC;
    path: AppRoute;
    tag: ApiTag;
    iconStrokeColored?: boolean;
  }[];
}

export const managerMenuList: Menu[] = [
  {
    title: 'Контакти',
    icon: ContactsSvg,
    path: AppRoute.CONTACTS,
    tag: ApiTag.CONTACTS
  },
  {
    title: 'Події',
    icon: EventsSvg,
    path: AppRoute.EVENTS,
    iconStrokeColored: true
  },
  {
    title: 'Завдання',
    icon: TaskSvg,
    path: AppRoute.TASKS
  }
];

export const menuList: Menu[] = [
  { title: 'Дашборд', icon: DashboardSvg, path: AppRoute.DASHBOARD },
  {
    title: 'Співробітники',
    icon: UsersSvg,
    path: AppRoute.USERS,
    tag: ApiTag.USERS
  },
  {
    title: 'Партнери',
    icon: PartnerSvg,
    path: AppRoute.PARTNERS,
    tag: ApiTag.PARTNERS
  },
  {
    title: 'Контакти',
    icon: ContactsSvg,
    path: AppRoute.CONTACTS,
    tag: ApiTag.CONTACTS
  },
  {
    title: 'Завдання',
    icon: TaskSvg,
    path: AppRoute.TASKS
  },
  {
    title: 'Події',
    icon: EventsSvg,
    path: AppRoute.EVENTS,
    iconStrokeColored: true,
    tag: ApiTag.EVENTS
  },
  {
    title: 'Проєкти',
    icon: ProjectsSvg,
    tag: ApiTag.PROJECTS,
    list: [
      {
        title: 'Дійсні проєкти',
        icon: ActualProjectsSvg,
        path: AppRoute.PROJECTS,
        tag: ApiTag.PROJECTS,
        iconStrokeColored: true
      },
      {
        title: 'Архів проєктів',
        icon: ArchProjectsSvg,
        path: AppRoute.PROJECTS_ARCH,
        tag: ApiTag.PROJECTS
      }

      // {
      //   title: 'Звіти',
      //   icon: ReportsSvg,
      //   path: AppRoute.REPORTS,
      //   iconStrokeColored: true
      // }
    ]
  }
  // {
  //   title: 'Події',
  //   icon: EventsSvg,
  //   path: AppRoute.EVENTS,
  //   iconStrokeColored: true
  // },
];
