import type { Dispatch, SetStateAction } from 'react';
import { AccordionBlock } from '../../../../components/accordion-block';
import { AccordionItem } from '../../../../components/accordion-item';
import { EditBtn } from '../../../../components/edit-btn';
import { UserInfo } from '../../../../components/user-info';
import { useGetUserMeQuery } from '../../../../api/user-api';
import { SidebarScrollBlock } from '../../../users/current-user/current-user-sidebar/sidebar-scroll-block';
import type { Task } from '../../../../models/task';
import { TableSelect } from '../../../../components/table-select';
import { TASK_STATUSES } from '../../../../common/constants';
import { useEditTaskMutation } from '../../../../api/task-api';
import { useNotification } from '../../../../hooks/use-notification';
import { getApiError } from '../../../../helpers/get-api-error';
import type { OptionItem } from '../../../../common/types/option-item';
import { dateTableFormat } from '../../../../helpers/date-helpers';
import {
  HeaderItem,
  TaskId,
  TaskSidebarHeader,
  TaskSidebarStyled
} from './style';

interface CurrenTaskSidebarProps {
  task?: Task;
  setEdit: Dispatch<SetStateAction<boolean>>;
}

export const CurrentTaskSidebar = ({
  task,
  setEdit
}: CurrenTaskSidebarProps) => {
  const { data: userMe } = useGetUserMeQuery('');
  const notification = useNotification();
  const [editTask] = useEditTaskMutation();

  const handleChangeStatus = (status: OptionItem<{ color?: string }>) => {
    if (task) {
      editTask({ id: task?.id, body: { status: status.id } })
        .unwrap()
        .catch((err) => {
          notification({
            title: 'Помилка',
            message: getApiError(err),
            type: 'error'
          });
        });
    }
  };

  if (!userMe) {
    return null;
  }

  return (
    <TaskSidebarStyled>
      {!!task && (
        <>
          <TaskSidebarHeader>
            <HeaderItem>
              <UserInfo lastName={task.title} firstName="" patronymic="" />
              <EditBtn onClick={() => setEdit(true)} />
            </HeaderItem>
            <HeaderItem>
              <TaskId>ID: {task.id}</TaskId>
              <TableSelect
                initialValue={{
                  id: task.status,
                  title:
                    TASK_STATUSES.find((item) => item.id === task.status)
                      ?.title || ''
                }}
                options={TASK_STATUSES}
                onSelect={handleChangeStatus}
                width="110px"
                color={
                  TASK_STATUSES.find((item) => item.id === task.status)
                    ?.restFields?.color
                }
              />
            </HeaderItem>
          </TaskSidebarHeader>
          <SidebarScrollBlock>
            <AccordionBlock maxHeight={900} title="Дані">
              <AccordionItem title="Назва" data={task.title} />
              <AccordionItem title="Опис" data={task.description} />
              <AccordionItem
                title="Виконати до"
                data={dateTableFormat(task.deadline)}
              />
            </AccordionBlock>
            <AccordionBlock title="Учасники завдання">
              <AccordionItem
                title="Відповідальний"
                data={task.resp_managers.map((item) => (
                  <p key={item.id}>{item.username}</p>
                ))}
              />
              <AccordionItem
                title="Завдання поставив"
                data={task.created_by.username}
              />
            </AccordionBlock>
          </SidebarScrollBlock>
        </>
      )}
    </TaskSidebarStyled>
  );
};
