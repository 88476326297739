import { Route, Routes } from 'react-router-dom';
import { LogIn } from './pages/log-in';
import { DashboardPage } from './pages/dashboard';
import {
  AppRoute,
  DashboardRoute,
  UsersRoute
} from './common/enums/app/app-route.enum';
import { UsersPage } from './pages/users';
import { ContactsPage } from './pages/contacts';
import { ProjectsPage } from './pages/projects';
import { ReportsPage } from './pages/reports';
import { UsersData } from './pages/users/users-data';
import { UserRoles } from './pages/users/user-roles';
import { UsersAccess } from './pages/users/users-access';
import { PublickRoute } from './components/public-route';
import { PrivateRoute } from './components/private-route';
import { ForgotPassword } from './pages/forgot-password';
import { ForgotPasswordRequest } from './pages/forgot-password-request';
import { CurrentProject } from './pages/projects/current-project';
import { CurrentEvent } from './pages/events/current-event';
import { ProjectsArchPage } from './pages/projects/projects-arch';
import { CurrentArchProject } from './pages/projects/projects-arch/current-arch-project';
import { Statistics } from './pages/events/statistics';
import { PartnersPage } from './pages/partners';
import { EventsPage } from './pages/events';
import { NotFoundPage } from './pages/not-found';
import { DashboardProjectsPage } from './pages/dashboard/projects';
import { AllDashboardProjects } from './pages/dashboard/projects/all-projects';
import { CurrentDashboardProject } from './pages/dashboard/projects/current-project';
import { CurrentDashboardEvent } from './pages/dashboard/projects/current-event';
import { AudiencePage } from './pages/dashboard/audience';
import { DashboardChildrenPage } from './pages/dashboard/children';
import { DashboardLivLocationPage } from './pages/dashboard/liv-location';
import { DashboardVolunteersPage } from './pages/dashboard/volunteers';
import { DashboardPartnersPage } from './pages/dashboard/partners';
import { TasksPage } from './pages/tasks';

export const App = () => (
  <Routes>
    <Route
      path={AppRoute.LOG_IN}
      element={<PublickRoute component={LogIn} />}
    />
    <Route
      path={AppRoute.FORGOT_PASSWORD}
      element={<PublickRoute component={ForgotPassword} />}
    />
    <Route
      path={AppRoute.FORGOT_PASSWORD_REQUEST}
      element={<PublickRoute component={ForgotPasswordRequest} />}
    />
    <Route
      path={AppRoute.USERS}
      element={<PrivateRoute component={UsersPage} />}
    >
      <Route path={UsersRoute.ROLES} element={<UserRoles />} />
      <Route path={UsersRoute.ACCESS} element={<UsersAccess />} />
      <Route index element={<UsersData />} />
    </Route>
    <Route
      path={AppRoute.DASHBOARD}
      element={<PrivateRoute customHeader component={DashboardPage} />}
    >
      <Route element={<DashboardProjectsPage />} path={DashboardRoute.PROJECTS}>
        <Route element={<CurrentDashboardProject />} path={`:id`} />
        <Route element={<AllDashboardProjects />} index />
        <Route element={<CurrentDashboardEvent />} path={`:id/:eventId`} />
      </Route>
      <Route element={<AudiencePage />} path={DashboardRoute.AUDIENCE} />
      <Route
        element={<DashboardChildrenPage />}
        path={DashboardRoute.CHILDREN}
      />
      <Route
        element={<DashboardLivLocationPage />}
        path={DashboardRoute.LOCATION}
      />
      <Route
        element={<DashboardLivLocationPage isReg />}
        path={DashboardRoute.LOCATION_REG}
      />
      <Route
        element={<DashboardVolunteersPage />}
        path={DashboardRoute.VOLUNTEER}
      />
      <Route
        element={<DashboardPartnersPage />}
        path={DashboardRoute.PARTNERS}
      />
    </Route>
    <Route
      path={AppRoute.PARTNERS}
      element={<PrivateRoute component={PartnersPage} />}
    />
    <Route
      path={AppRoute.CONTACTS}
      element={<PrivateRoute component={ContactsPage} />}
    />
    <Route
      path={AppRoute.PROJECTS}
      element={<PrivateRoute component={ProjectsPage} />}
    />
    <Route
      path={AppRoute.EVENTS}
      element={<PrivateRoute component={EventsPage} />}
    />
    <Route
      path={AppRoute.TASKS}
      element={<PrivateRoute component={TasksPage} />}
    />
    <Route
      path={AppRoute.PROJECTS_ARCH}
      element={<PrivateRoute component={ProjectsArchPage} />}
    />
    <Route
      path={`${AppRoute.PROJECTS_ARCH}/:projectId`}
      element={<PrivateRoute component={CurrentArchProject} />}
    />
    <Route
      path={`${AppRoute.PROJECTS}/:projectId`}
      element={<PrivateRoute component={CurrentProject} />}
    />
    <Route
      path={`${AppRoute.PROJECTS}${AppRoute.EVENTS}/:eventId`}
      element={<PrivateRoute component={CurrentEvent} />}
    />
    <Route
      path={`${AppRoute.PROJECTS}${AppRoute.EVENTS}/:eventId${AppRoute.STATISTICS}`}
      element={<PrivateRoute component={Statistics} />}
    />
    <Route
      path={AppRoute.REPORTS}
      element={<PrivateRoute component={ReportsPage} />}
    />
    <Route
      path={AppRoute.ANY}
      element={<PrivateRoute component={NotFoundPage} />}
    />
    <Route
      path={AppRoute.ROOT}
      element={<PrivateRoute component={DashboardPage} />}
    />
  </Routes>
);
