import styled from 'styled-components';
import { centerFlex, scrollBar } from '../../style/helpers';

interface TableStyledProps {
  checked?: boolean;
  canClick?: boolean;
  withOutHover?: boolean;
}

interface CellProps {
  isColored?: boolean;
  canBreack?: boolean;
}

export const TableWrapper = styled.div<{ customHeight?: string; mt?: string }>`
  margin-top: ${(p) => p.mt || '30px'};
  width: 100%;
  max-height: calc(100% - 282px);
  @supports (overflow-anchor: auto) and (overflow-y: overlay) {
    overflow-y: overlay;
  }
  ${scrollBar}
  ::-webkit-scrollbar-track {
    margin-top: 46px;
  }
`;

export const TableActionsWrapper = styled.div`
  position: sticky;
  top: 0;
  left: 0;
`;

export const TableActions = styled.div`
  padding: 0 20px;
  height: 46px;
  display: flex;
  align-items: center;
  gap: 20px;
  background: ${(p) => p.theme.colors.grey.table};
  border-top: 0.5px solid ${(p) => p.theme.colors.grey.input};
`;

export const TableActionsCount = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${(p) => p.theme.colors.dark};
  min-width: 55px;
  display: flex;
  align-items: center;
`;

export const ClearCheckedBtn = styled.div`
  margin-left: 5px;
  cursor: pointer;
  ${centerFlex}
`;

export const TableActionsItem = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.2px;
  color: ${(p) => p.theme.colors.blue.primary};
  span {
    margin-left: 4px;
  }
  cursor: pointer;
`;

export const CheckedAllPages = styled.div`
  height: 60px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  color: ${(p) => p.theme.colors.dark};
  font-size: 12px;
  font-weight: 400;
  background: ${(p) => p.theme.colors.white};
  span {
    font-weight: 600;
    color: ${(p) => p.theme.colors.blue.primary};
    cursor: pointer;
    margin-left: 5px;
  }
`;

export const TableStyled = styled.table<{ isFetching?: boolean }>`
  width: 100%;
  border-spacing: 0;
  opacity: ${(p) => (p.isFetching ? '0.6' : '1')};
  pointer-events: ${(p) => (p.isFetching ? 'none' : 'all')};
`;

export const Row = styled.tr<TableStyledProps>`
  height: 60px;
  transition: background-color 0.2s;
  background-color: ${(p) =>
    p.checked ? p.theme.colors.blue.bg : 'transpqrent'};
  :hover {
    transition: background-color 0.2s;
    background-color: ${(p) =>
      p.withOutHover ? p.theme.colors.white : p.theme.colors.blue.bg};
  }
  cursor: ${(p) => (p.canClick ? 'pointer' : 'default')};
`;

export const THead = styled.thead`
  height: 46px;
  ${Row} {
    height: 46px;
  }
`;

export const TBody = styled.tbody<TableStyledProps>`
  background: #ffff;
  overflow: auto;
`;

export const Th = styled.th<{
  showActions?: boolean;
  withSublist?: boolean;
  showList?: boolean;
  minWidth?: string;
  showCheckAllPages?: boolean;
}>`
  min-width: ${(p) => p.minWidth};
  background: ${(p) =>
    p.showList && p.withSublist
      ? p.theme.colors.primary
      : p.theme.colors.grey.table};
  border: 0.5px solid ${(p) => p.theme.colors.grey.input};
  text-align: left;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  text-transform: uppercase;
  color: ${(p) =>
    p.showList ? p.theme.colors.white : p.theme.colors.blue.dark};
  white-space: nowrap;
  padding: 0 16px;
  :first-child {
    width: 46px;
    border-left: none;
    label {
      margin: 0 auto;
    }
  }
  position: sticky;
  top: ${(p) =>
    p.showActions ? (p.showCheckAllPages ? '106px' : '46px') : '0'};
  transition: top 300ms;
  transition-delay: ${(p) => (p.showActions ? '100ms' : '0')};
  > svg {
    transform: ${(p) => (p.showList ? 'rotate(180deg)' : 'rotate(0)')};
    margin-left: 12px;
    vertical-align: middle;
    margin-bottom: 3px;
    path {
      stroke: ${(p) =>
        p.showList ? p.theme.colors.white : p.theme.colors.blue.dark};
    }
  }
  :hover {
    color: ${(p) =>
      p.withSublist
        ? p.showList
          ? p.theme.colors.white
          : p.theme.colors.blue.primary
        : p.theme.colors.blue.dark};
    > svg {
      path {
        stroke: ${(p) =>
          p.withSublist
            ? p.showList
              ? p.theme.colors.white
              : p.theme.colors.blue.primary
            : p.theme.colors.blue.dark};
      }
    }
  }
  cursor: ${(p) => (p.withSublist ? 'pointer' : 'default')};
  z-index: 1;
`;

export const Cell = styled.td<CellProps>`
  padding: 0 16px;
  border: 0.5px solid ${(p) => p.theme.colors.grey.table};
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: ${(p) =>
    p.isColored ? p.theme.colors.blue.primary : p.theme.colors.blue.dark};
  max-width: 300px;
  span {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    line-clamp: 4;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    white-space: ${(p) => (p.canBreack ? 'normal' : 'nowrap')};
  }
  :first-child {
    width: 46px;
    border-left: none;
    label {
      margin: 0 auto;
    }
  }
  :last-child {
    padding-right: 20px;
  }
`;
