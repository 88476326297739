import { ApiPath } from '../common/enums/http/api-path.enum';
import { ApiTag } from '../common/enums/http/api-tag.enum';
import { HttpMethod } from '../common/enums/http/http-method.enum';
import type { ReponseWithPagination } from '../common/types/response-with-pagination';
import type { Call } from '../models/call';
import type { Changes } from '../models/changes';
import type { PartnersCities } from '../models/dashboard';
import type { Meet } from '../models/meet';
import type { Note } from '../models/note';
import type { Partner } from '../models/partner';
import { commonApi } from './common-api';

export const partnerApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    getPartners: builder.query<
      ReponseWithPagination<Partner>,
      { params: string }
    >({
      query: ({ params }) => `${ApiPath.PARTNERS}${ApiPath.ROOT}${params}`,
      providesTags: [ApiTag.PARTNERS]
    }),
    getCurrentPartner: builder.query<Partner, { id: string | null }>({
      query: ({ id }) =>
        `${ApiPath.PARTNERS}${ApiPath.ROOT}${id}${ApiPath.ROOT}`,
      providesTags: [ApiTag.PARTNER]
    }),
    createPartner: builder.mutation<Partner, Record<string, unknown>>({
      query: (data) => ({
        url: `${ApiPath.PARTNERS}${ApiPath.ROOT}`,
        method: HttpMethod.POST,
        body: data
      }),
      invalidatesTags: [ApiTag.PARTNERS, ApiTag.PARTNER]
    }),
    editPartner: builder.mutation<
      Partner,
      { id: string | number; body: FormData | Record<string, unknown> }
    >({
      query: ({ id, body }) => ({
        url: `${ApiPath.PARTNERS}${ApiPath.ROOT}${id}${ApiPath.ROOT}`,
        method: HttpMethod.PATCH,
        body
      }),
      invalidatesTags: [ApiTag.PARTNERS, ApiTag.PARTNER]
    }),
    deletePartner: builder.mutation<Partner, { ids: (string | number)[] }>({
      query: ({ ids }) => ({
        url: `${ApiPath.PARTNERS}${ApiPath.ROOT}?ids=${ids.join(',')}`,
        method: HttpMethod.DELETE
      }),
      invalidatesTags: [ApiTag.PARTNERS]
    }),
    createPartnerCall: builder.mutation<
      Call,
      Record<string, unknown> | FormData
    >({
      query: (data) => ({
        url: `${ApiPath.PARTNER_CALLS}${ApiPath.ROOT}`,
        method: HttpMethod.POST,
        body: data
      }),
      invalidatesTags: [ApiTag.PARTNER]
    }),
    editPartnerCall: builder.mutation<
      null,
      { id: string | number; body: Record<string, unknown> }
    >({
      query: ({ id, body }) => ({
        url: `${ApiPath.PARTNER_CALLS}${ApiPath.ROOT}${id}${ApiPath.ROOT}`,
        method: HttpMethod.PATCH,
        body
      }),
      invalidatesTags: [ApiTag.PARTNER]
    }),
    deletePartnerCall: builder.mutation<null, { id: string | number }>({
      query: ({ id }) => ({
        url: `${ApiPath.PARTNER_CALLS}${ApiPath.ROOT}${id}`,
        method: HttpMethod.DELETE
      }),
      invalidatesTags: [ApiTag.PARTNER]
    }),
    createPartnerMeet: builder.mutation<
      Meet,
      Record<string, unknown> | FormData
    >({
      query: (data) => ({
        url: `${ApiPath.PARTNER_MEETS}${ApiPath.ROOT}`,
        method: HttpMethod.POST,
        body: data
      }),
      invalidatesTags: [ApiTag.PARTNER]
    }),
    editPartnerMeet: builder.mutation<
      null,
      { id: string | number; body: Record<string, unknown> }
    >({
      query: ({ id, body }) => ({
        url: `${ApiPath.PARTNER_MEETS}${ApiPath.ROOT}${id}${ApiPath.ROOT}`,
        method: HttpMethod.PATCH,
        body
      }),
      invalidatesTags: [ApiTag.PARTNER]
    }),
    deletePartnerMeet: builder.mutation<null, { id: string | number }>({
      query: ({ id }) => ({
        url: `${ApiPath.PARTNER_MEETS}${ApiPath.ROOT}${id}`,
        method: HttpMethod.DELETE
      }),
      invalidatesTags: [ApiTag.PARTNER]
    }),
    createPartnerNote: builder.mutation<
      Note,
      Record<string, unknown> | FormData
    >({
      query: (data) => ({
        url: `${ApiPath.PARTNER_NOTES}${ApiPath.ROOT}`,
        method: HttpMethod.POST,
        body: data
      }),
      invalidatesTags: [ApiTag.PARTNER]
    }),
    editPartnerNote: builder.mutation<
      null,
      { id: string | number; body: { text: string } }
    >({
      query: ({ id, body }) => ({
        url: `${ApiPath.PARTNER_NOTES}${ApiPath.ROOT}${id}${ApiPath.ROOT}`,
        method: HttpMethod.PATCH,
        body
      }),
      invalidatesTags: [ApiTag.PARTNER]
    }),
    deletePartnerNote: builder.mutation<null, { id: string | number }>({
      query: ({ id }) => ({
        url: `${ApiPath.PARTNER_NOTES}${ApiPath.ROOT}${id}`,
        method: HttpMethod.DELETE
      }),
      invalidatesTags: [ApiTag.PARTNER]
    }),
    getPartnerChanges: builder.query<Changes, { id: number | string }>({
      query: ({ id }) => `${ApiPath.PARTNER_CHANGES}${ApiPath.ROOT}${id}`,
      providesTags: [ApiTag.PARTNER]
    }),
    getPartnersCities: builder.query<PartnersCities[], string>({
      query: () => ApiPath.PARTNERS_CITIES
    })
  }),
  overrideExisting: false
});

export const {
  useGetPartnersQuery,
  useCreatePartnerMutation,
  useGetCurrentPartnerQuery,
  useDeletePartnerMutation,
  useEditPartnerMutation,
  useCreatePartnerCallMutation,
  useEditPartnerCallMutation,
  useDeletePartnerCallMutation,
  useCreatePartnerMeetMutation,
  useEditPartnerMeetMutation,
  useDeletePartnerMeetMutation,
  useCreatePartnerNoteMutation,
  useDeletePartnerNoteMutation,
  useEditPartnerNoteMutation,
  useGetPartnerChangesQuery,
  useGetPartnersCitiesQuery
} = partnerApi;
