import { useEffect, useRef, useState } from 'react';
import { BellSvg } from '../../svg/bell-svg';
import {
  useGetTaskNotifsQuery,
  useReadAllNotifsMutation
} from '../../../api/task-api';
import { useOutsideClick } from '../../../hooks/use-outside-click';
import { useGetUserMeQuery } from '../../../api/user-api';
import { WS_URL } from '../../../common/constants';
import { Badge, HeaderNotifIcon, HeaderNotifStyled } from './style';
import { HeaderNotifContent } from './header-notif-content';

export const HeaderNotif = () => {
  const ref = useRef<HTMLDivElement>(null);
  useOutsideClick(ref, () =>
    setShowNotif((state) => {
      if (state) {
        readAll({ read_all: true });
      }

      return false;
    })
  );
  const [showNotif, setShowNotif] = useState(false);
  const [messageCount, setMessageCount] = useState(0);

  const { data: userMe } = useGetUserMeQuery('');
  const { data: notifs } = useGetTaskNotifsQuery({ params: `?size=${1}` });

  const [readAll] = useReadAllNotifsMutation();

  useEffect(() => {
    let socket: WebSocket | null = null;

    const handleMessages = (e: MessageEvent) => {
      const socketData = JSON.parse(e.data);
      if (socketData?.type === 'notifs') {
        setMessageCount(socketData?.unread_count || 0);
      }
    };

    if (userMe && !socket) {
      socket = new WebSocket(`${WS_URL}?user_id=${userMe.id}`);
      socket.addEventListener('message', handleMessages);
    }

    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [userMe]);

  const toggleNotif = () => {
    setShowNotif((state) => {
      if (state) {
        readAll({ read_all: true });
      }

      return !state;
    });
  };

  return (
    <HeaderNotifStyled ref={ref}>
      <HeaderNotifIcon onClick={toggleNotif}>
        <BellSvg />
        {(!!messageCount ||
          (!!notifs?.results.length && !notifs.results[0].is_read)) && (
          <Badge />
        )}
      </HeaderNotifIcon>
      {showNotif && <HeaderNotifContent />}
    </HeaderNotifStyled>
  );
};
