import { ApiPath } from '../common/enums/http/api-path.enum';
import { ApiTag } from '../common/enums/http/api-tag.enum';
import { HttpMethod } from '../common/enums/http/http-method.enum';
import type { ReponseWithPagination } from '../common/types/response-with-pagination';
import type { NoteActivity } from '../models/activity';
import type { Change } from '../models/changes';
import type { Task } from '../models/task';
import type { TaskNotif } from '../models/task-notif';
import { commonApi } from './common-api';

export const taskApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    getTasks: builder.query<ReponseWithPagination<Task>, { params: string }>({
      query: ({ params }) => `${ApiPath.TASKS}${ApiPath.ROOT}${params}`,
      providesTags: [ApiTag.TASKS]
    }),
    getCurrentTask: builder.query<Task, { id: string | null }>({
      query: ({ id }) => `${ApiPath.TASKS}${ApiPath.ROOT}${id}${ApiPath.ROOT}`,
      providesTags: [ApiTag.TASK]
    }),
    createTask: builder.mutation<Task, Record<string, unknown>>({
      query: (data) => ({
        url: `${ApiPath.TASKS}${ApiPath.ROOT}`,
        method: HttpMethod.POST,
        body: data
      }),
      invalidatesTags: [ApiTag.TASKS, ApiTag.TASK]
    }),
    editTask: builder.mutation<
      Task,
      { id: string | number; body: FormData | Record<string, unknown> }
    >({
      query: ({ id, body }) => ({
        url: `${ApiPath.TASKS}${ApiPath.ROOT}${id}${ApiPath.ROOT}`,
        method: HttpMethod.PATCH,
        body
      }),
      invalidatesTags: [ApiTag.TASKS, ApiTag.TASK]
    }),
    deleteTask: builder.mutation<Task, { ids: (string | number)[] }>({
      query: ({ ids }) => ({
        url: `${ApiPath.TASKS}${ApiPath.ROOT}?ids=${ids.join(',')}`,
        method: HttpMethod.DELETE
      }),
      invalidatesTags: [ApiTag.TASKS]
    }),
    deleteTaskNote: builder.mutation<null, { id: string | number }>({
      query: ({ id }) => ({
        url: `${ApiPath.ALL_TASKS_NOTES}${ApiPath.ROOT}${id}${ApiPath.ROOT}`,
        method: HttpMethod.DELETE
      }),
      invalidatesTags: [ApiTag.TASK]
    }),
    editTaskNote: builder.mutation<
      null,
      { id: string | number; body: { text: string } }
    >({
      query: ({ id, body }) => ({
        url: `${ApiPath.ALL_TASKS_NOTES}${ApiPath.ROOT}${id}${ApiPath.ROOT}`,
        method: HttpMethod.PATCH,
        body
      }),
      invalidatesTags: [ApiTag.TASK]
    }),
    getTaskChanges: builder.query<
      { activities: [string, Change | NoteActivity][] },
      { id: number | string }
    >({
      query: ({ id }) => `${ApiPath.TASK_CHANGES}${ApiPath.ROOT}${id}`,
      providesTags: [ApiTag.TASK]
    }),
    getTaskNotifs: builder.query<
      ReponseWithPagination<TaskNotif>,
      { params: string }
    >({
      query: ({ params }) => `${ApiPath.TASKS_NOTIFS}${ApiPath.ROOT}${params}`,
      providesTags: [ApiTag.TASKS_NOTIFS]
    }),
    readAllNotifs: builder.mutation<Task, { read_all: boolean }>({
      query: (data) => ({
        url: ApiPath.READ_ALL_NOTIFS,
        method: HttpMethod.POST,
        body: data
      }),
      invalidatesTags: [ApiTag.TASKS_NOTIFS]
    })
  }),
  overrideExisting: false
});

export const {
  useGetTasksQuery,
  useCreateTaskMutation,
  useGetCurrentTaskQuery,
  useDeleteTaskMutation,
  useEditTaskMutation,
  useGetTaskNotifsQuery,
  useDeleteTaskNoteMutation,
  useEditTaskNoteMutation,
  useGetTaskChangesQuery,
  useReadAllNotifsMutation
} = taskApi;
