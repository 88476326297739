import type { FormEventHandler } from 'react';
import { useState } from 'react';
import { dateWithTime } from '../../../helpers/date-helpers';
import type { Note as INote } from '../../../models/note';
import { FlexContainer } from '../../flex-container';
import { AngleSvg } from '../../svg/angle-svg';
import { useGetUserMeQuery } from '../../../api/user-api';
import { TrashSvg } from '../../svg/trash-svg';
import { EditSvg } from '../../svg/edit-svg';
import { BtnSecondary } from '../../btn-secondary';
import { BtnPrimary } from '../../btn-primary';
import { useNotification } from '../../../hooks/use-notification';
import { getApiError } from '../../../helpers/get-api-error';
import { ConfirmPopup } from '../../confirm-popup';
import type {
  DeleteNote,
  EditNote
} from '../../../common/types/rtk-triggers/note';
import {
  ActionBtn,
  ActionContainer,
  EditBtns,
  EditForm,
  ElipsedText,
  FullText,
  HeaderDate,
  NoteHeader,
  NoteStyled,
  NoteTitle,
  TextAreaStyled,
  TitleAngle
} from './style';

interface NoteProps {
  note: INote;
  editNote: EditNote;
  deleteNote: DeleteNote;
}

export const Note = ({ note, editNote, deleteNote }: NoteProps) => {
  const notification = useNotification();
  const { data: userMe } = useGetUserMeQuery('');
  const [expand, setExpand] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editNoteText, setEditNoteText] = useState(note.text);
  const [showConfirm, setShowConfirm] = useState(false);

  const handleDelete = () => {
    deleteNote({ id: note.id })
      .unwrap()
      .then(() => {
        notification({
          title: 'Нотатку видалено',
          type: 'success',
          message: 'Ви успішно видалили нотатку'
        });
        setShowConfirm(false);
      })
      .catch((err) => {
        notification({
          title: 'Помилка',
          type: 'error',
          message: getApiError(err)
        });
      });
  };

  const handleCancelEdit = () => {
    setEdit(false);
    setEditNoteText(note.text);
  };

  const handleEdit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    editNote({ id: note.id, body: { text: editNoteText } })
      .unwrap()
      .then(() => {
        setEdit(false);
        notification({
          title: 'Нотатку відредаговано',
          type: 'success',
          message: 'Ви успішно відредагували нотатку'
        });
      })
      .catch((err) => {
        notification({
          title: 'Помилка',
          type: 'error',
          message: getApiError(err)
        });
      });
  };

  const toggleEdit = () => {
    setEdit((state) => {
      if (!state) {
        setExpand(true);
      }

      return !state;
    });
  };

  return (
    <NoteStyled>
      <NoteHeader>
        <NoteTitle onClick={() => setExpand((state) => !state)}>
          <TitleAngle isOpen={expand}>
            <AngleSvg />
          </TitleAngle>
          <span>Нотатка </span>від{' '}
          {note.contact_note_owner?.full_name ||
            note.contact_note_owner?.username ||
            note.partner_note_owner?.full_name ||
            note.partner_note_owner?.username ||
            note.task_note_owner?.full_name ||
            note.task_note_owner?.username}
        </NoteTitle>
        <FlexContainer>
          {(expand &&
            ((userMe?.id || '') === note.contact_note_owner?.id ||
              (userMe?.id || '') === note.partner_note_owner?.id)) ||
            ((userMe?.id || '') === note.task_note_owner?.id && (
              <ActionContainer>
                <ActionBtn onClick={() => setShowConfirm(true)}>
                  <TrashSvg />
                </ActionBtn>{' '}
                |{' '}
                <ActionBtn onClick={toggleEdit}>
                  <EditSvg />
                </ActionBtn>
              </ActionContainer>
            ))}
          <HeaderDate>{dateWithTime(note.created_at)}</HeaderDate>
        </FlexContainer>
      </NoteHeader>
      {expand ? (
        edit ? (
          <EditForm onSubmit={handleEdit}>
            <TextAreaStyled
              value={editNoteText}
              onChange={(e) => setEditNoteText(e.target.value)}
            ></TextAreaStyled>
            <EditBtns>
              <BtnSecondary onClick={handleCancelEdit}>Відмінити</BtnSecondary>
              <BtnPrimary disabled={!editNoteText}>Зберегти</BtnPrimary>
            </EditBtns>
          </EditForm>
        ) : (
          <FullText>
            <pre>{note.text}</pre>
          </FullText>
        )
      ) : (
        <ElipsedText>{note.text}</ElipsedText>
      )}
      <ConfirmPopup
        show={showConfirm}
        setShow={setShowConfirm}
        title="Ви дійсно хочете видалити нотатку"
        onSubmit={handleDelete}
        needTranslate
      />
    </NoteStyled>
  );
};
