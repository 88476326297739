import type { FC } from 'react';
import { ActivitySvg } from '../../../../components/svg/activity-svg';
import { FileSvg } from '../../../../components/svg/file-svg';
import { ViewSvg } from '../../../../components/svg/view-svf';

export const tabList: { id: string; title: string; icon?: FC }[] = [
  { id: 'activity', title: 'Активність', icon: ViewSvg },
  { id: 'note', title: 'Нотатки', icon: ActivitySvg },
  { id: 'files', title: 'Файли', icon: FileSvg }
];
