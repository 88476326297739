import styled from 'styled-components';

export const SubheaderSelectStyled = styled.div`
  margin: 0 10px;
  position: relative;
`;

export const SelectTitle = styled.div<{ isActive?: boolean }>`
  position: relative;
  padding: 10px 12px;
  height: 40px;
  background: ${(p) =>
    p.isActive ? p.theme.colors.primary : p.theme.colors.white};
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: -0.2px;
  color: ${(p) =>
    p.isActive ? p.theme.colors.white : p.theme.colors.blue.dark};
  border: 1px solid
    ${(p) => (p.isActive ? p.theme.colors.primary : p.theme.colors.grey.input)};
  border-radius: 6px;
  cursor: pointer;
  svg {
    path {
      fill: ${(p) =>
        p.isActive ? p.theme.colors.white : p.theme.colors.grey.light};
    }
  }

  @media (max-width: ${(p) => p.theme.breakpoints.xl}) {
    span {
      display: none;
    }
  }
`;

export const Options = styled.div<{ $showChildrenLeft?: boolean }>`
  position: absolute;
  top: calc(100% + 6px);
  z-index: 2;

  @media (max-width: 1880px) {
    left: ${(p) => (p.$showChildrenLeft ? 'unset' : '0')};
    right: ${(p) => (p.$showChildrenLeft ? '0' : 'unset')};
  }
`;
