import styled from 'styled-components';

export const MainLayoutStyled = styled.div`
  display: flex;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const Mobile = styled.div`
  display: none;
  @media (max-width: 768px) {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`;

export const Content = styled.div<{ menuIsOpen: boolean }>`
  position: relative;
  width: ${(p) =>
    p.menuIsOpen ? 'calc(100vw - 232px)' : 'calc(100vw - 56px)'};
  height: 100vh;
  transition: all 0.4s;
`;
