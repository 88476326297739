import styled from 'styled-components';

export const FormStyled = styled.form``;

export const FormTitle = styled.div`
  margin-top: 30px;
  font-weight: 700;
  font-size: 22px;
  line-height: 140%;
  color: ${(p) => p.theme.colors.blue.dark};
`;

export const InputsContainer = styled.div`
  margin: 30px 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: start;
  column-gap: 30px;
  row-gap: 20px;
`;

export const InputGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
`;

export const GroupTitle = styled.div`
  margin-top: 30px;
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;
  color: ${(p) => p.theme.colors.blue.dark};
`;

export const ManagerItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 16px;
  gap: 12px;
  align-items: end;
  svg {
    max-width: 11px;
    path {
      fill: ${(p) => p.theme.colors.grey.light};
    }
  }
`;
