import styled from 'styled-components';
import { centerFlex, scrollBar } from '../../../../style/helpers';

export const ActivityListStyled = styled.div`
  margin-top: 40px;
  padding: 0 20px;
  height: calc(100vh - 211px);
  ${scrollBar}
`;

export const GroupByMonth = styled.div`
  margin-top: 20px;
  :first-child {
    margin-top: 0;
  }
`;

export const GroupByMonthTitle = styled.div`
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
  color: ${(p) => p.theme.colors.blue.dark};
`;

export const ChamgeMessage = styled.div`
  margin-top: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: ${(p) => p.theme.colors.blue.dark};
`;

export const Loader = styled.div`
  ${centerFlex}
`;
