export const BellSvg = () => (
  <svg
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.16433 14.3135C5.42485 14.3135 3.68537 14.0398 2.03547 13.4924C1.40836 13.2779 0.930564 12.8414 0.721527 12.2718C0.505025 11.7023 0.579681 11.0735 0.923098 10.5113L1.78164 9.09847C1.96081 8.80259 2.12506 8.21082 2.12506 7.86315V5.72538C2.12506 2.97365 4.38713 0.732316 7.16433 0.732316C9.94153 0.732316 12.2036 2.97365 12.2036 5.72538V7.86315C12.2036 8.20342 12.3678 8.80259 12.547 9.10587L13.3981 10.5113C13.7191 11.0439 13.7788 11.6875 13.5623 12.2718C13.3458 12.8562 12.8755 13.3 12.2857 13.4924C10.6433 14.0398 8.90381 14.3135 7.16433 14.3135ZM7.16433 1.84189C5.00677 1.84189 3.2449 3.58021 3.2449 5.72538V7.86315C3.2449 8.40314 3.02093 9.20203 2.7447 9.66805L1.88616 11.0809C1.72192 11.3546 1.67712 11.6431 1.77418 11.8872C1.86376 12.1387 2.08773 12.331 2.39382 12.4346C5.51443 13.4702 8.82169 13.4702 11.9423 12.4346C12.2111 12.3458 12.4201 12.1461 12.5172 11.8798C12.6142 11.6135 12.5918 11.325 12.4425 11.0809L11.584 9.66805C11.3003 9.18724 11.0838 8.39575 11.0838 7.85576V5.72538C11.0838 3.58021 9.32935 1.84189 7.16433 1.84189Z"
      fill="#9DABC0"
    />
    <path
      d="M8.55293 2.04161C8.50067 2.04161 8.44841 2.03421 8.39615 2.01942C8.17965 1.96024 7.97061 1.91586 7.76904 1.88627C7.13447 1.8049 6.52229 1.84928 5.94744 2.01942C5.7384 2.08599 5.51443 2.01942 5.37259 1.86408C5.23074 1.70874 5.18595 1.48682 5.26807 1.2871C5.57416 0.510402 6.32072 0 7.17179 0C8.02287 0 8.76943 0.503005 9.07552 1.2871C9.15018 1.48682 9.11285 1.70874 8.971 1.86408C8.85902 1.98243 8.70224 2.04161 8.55293 2.04161Z"
      fill="#9DABC0"
    />
    <path
      d="M7.16433 16C6.42524 16 5.70854 15.7041 5.18595 15.1863C4.66336 14.6685 4.36473 13.9584 4.36473 13.2261H5.48457C5.48457 13.6625 5.66375 14.0915 5.9773 14.4022C6.29086 14.7129 6.72386 14.8904 7.16433 14.8904C8.09006 14.8904 8.84409 14.1433 8.84409 13.2261H9.96392C9.96392 14.7573 8.70971 16 7.16433 16Z"
      fill="#9DABC0"
    />
  </svg>
);
