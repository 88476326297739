import type React from 'react';
import { useRef, useState } from 'react';
import type { OptionItem } from '../../common/types/option-item';
import { useOutsideClick } from '../../hooks/use-outside-click';
import { AngleSvg } from '../svg/angle-svg';
import {
  OptionsItem,
  TableSelectOptions,
  TableSelectStyled,
  TableSelectWrapper
} from './style';

interface TableSelectProps {
  openTop?: boolean;
  initialValue: OptionItem<{ color?: string }>;
  onSelect: (value: OptionItem<{ color?: string }>) => void;
  options: OptionItem<{ color?: string }>[];
  disabled?: boolean;
  withoutBg?: boolean;
  color?: string;
  isFixedPosition?: boolean;
  width?: string;
}

export const TableSelect = ({
  openTop,
  initialValue,
  onSelect,
  options,
  disabled,
  withoutBg,
  color,
  isFixedPosition,
  width
}: TableSelectProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [showOtions, setShowOptions] = useState(false);
  const [fixedPosition, setFixedPosition] = useState<{
    top: string;
    left: string;
    width: string;
  } | null>(null);
  useOutsideClick(ref, () => setShowOptions(false));

  const handleSelect = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    item: OptionItem<{ color?: string }>
  ) => {
    e.stopPropagation();
    onSelect(item);
    setShowOptions(false);
  };

  const handleOpen = () => {
    if (isFixedPosition) {
      const rect = ref.current?.getBoundingClientRect();
      if (rect) {
        setFixedPosition({
          left: `${rect.left}px`,
          top: `${rect.top + 34}px`,
          width: `${rect.width}px`
        });
      }
    }
    setShowOptions((state) => !state);
  };

  return (
    <TableSelectWrapper ref={ref} onClick={(e) => e.stopPropagation()}>
      <TableSelectStyled
        onClick={handleOpen}
        $isOpen={showOtions}
        $disabled={disabled}
        $withoutBg={withoutBg}
        $color={color}
        $width={width}
      >
        {initialValue.title || '-'}
        {!disabled && <AngleSvg />}
      </TableSelectStyled>
      {showOtions && !disabled && (
        <TableSelectOptions
          $openTop={openTop}
          style={
            isFixedPosition
              ? {
                  position: 'fixed',
                  width: fixedPosition?.width,
                  left: fixedPosition?.left,
                  top: fixedPosition?.top
                }
              : undefined
          }
        >
          {options.map((item) => (
            <OptionsItem key={item.id} onClick={(e) => handleSelect(e, item)}>
              {item.title || '-'}
            </OptionsItem>
          ))}
        </TableSelectOptions>
      )}
    </TableSelectWrapper>
  );
};
