import type { ChangeEventHandler, FormEventHandler } from 'react';
import { useState, type Dispatch, type SetStateAction } from 'react';
import { FormBtns } from '../../../components/form-btns';
import { Input } from '../../../components/input';
import type { OptionItem } from '../../../common/types/option-item';
import { Select } from '../../../components/select';
import { TextArea } from '../../../components/text-area';
import { InputDate } from '../../../components/input-date';
import { useGetUserMeQuery, useGetUsersQuery } from '../../../api/user-api';
import { useGetProjectsQuery } from '../../../api/projects-api';
import { CheckBox } from '../../../components/checkbox';
import { ManagerItem } from '../../events/events-form/style';
import { mapUsersToProps } from '../../../helpers/map-users-to-option';
import { IconContainer } from '../../../components/icon-container';
import { CloseEditSvg } from '../../../components/svg/close-edit-svg';
import { AddItem } from '../../../components/add-item/indes';
import { AddTaskStyled, Col, InputsContainer } from './style';

interface TextFields {
  name: string;
  description: string;
}

interface TaskFormProps {
  setShow: Dispatch<SetStateAction<boolean>>;
  fields: TextFields;
  handleFieldsChange: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  >;
  highPriority: boolean;
  setHighPriority: (checked: boolean) => void;
  responsible: OptionItem[];
  setResponsible: Dispatch<SetStateAction<OptionItem[]>>;
  project: OptionItem | null;
  setProject: (value: OptionItem) => void;
  date: string;
  setDate: (date: string) => void;
  onSubmit: () => void;
  isLoading: boolean;
}

export const TaskForm = ({
  setShow,
  fields,
  handleFieldsChange,
  highPriority,
  setHighPriority,
  responsible,
  setResponsible,
  date,
  setDate,
  onSubmit,
  isLoading,
  project,
  setProject
}: TaskFormProps) => {
  const { data: userMe } = useGetUserMeQuery('');
  const [termResponsible, setTermResponsible] = useState('');
  const [debounceResponsibleTerm, setDebounceResponsibleTerm] = useState('');

  const { data: users, isFetching: isFetchingUsers } = useGetUsersQuery(
    { params: `?size=100&search=${debounceResponsibleTerm}` },
    { refetchOnMountOrArgChange: true }
  );
  const { data: allProjects } = useGetProjectsQuery(
    { params: '?size=100' },
    {
      skip: !userMe
        ? true
        : userMe.is_superuser
        ? false
        : !userMe.role?.view_project
    }
  );

  const notAllFieldsAreFilled =
    !fields.name ||
    !fields.description ||
    (responsible.length && !responsible[0].id) ||
    !date;

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    onSubmit();
  };

  const handleDeleteManager = (i: number) => {
    if (responsible && responsible.length === 1) {
      setResponsible([{ id: '', title: '' }]);
    } else {
      setResponsible((state) => state.filter((_, j) => j !== i));
    }
  };

  return (
    <AddTaskStyled onSubmit={handleSubmit}>
      <InputsContainer>
        <Col>
          <Input
            label="Назва*"
            value={fields.name}
            name="name"
            onChange={handleFieldsChange}
            required
          />
          <TextArea
            label="Опис*"
            name="description"
            onChange={handleFieldsChange}
            value={fields.description}
          />
          <CheckBox
            checked={highPriority}
            setChecked={setHighPriority}
            label="Важливе"
          />
        </Col>
        <Col>
          <InputDate label="Виконати до" value={date} setValue={setDate} />
          <Select
            label="Проект"
            options={
              allProjects?.results.map((item) => ({
                id: item.id,
                title: item.name
              })) || []
            }
            value={project || { id: '', title: '' }}
            setValue={setProject}
          />
          {responsible.map((item, i) => (
            <ManagerItem key={i}>
              <Select
                label="Відповідальний"
                value={item}
                setValue={(value) =>
                  setResponsible((state) =>
                    state.map((manager, j) => (i === j ? value : manager))
                  )
                }
                options={mapUsersToProps(users?.results)}
                isLoading={isFetchingUsers}
                isSearch
                term={termResponsible}
                setTerm={setTermResponsible}
                setDebounceTerm={setDebounceResponsibleTerm}
              />
              {responsible.length === 1 && !responsible[0].id ? (
                ''
              ) : (
                <IconContainer
                  m="0 0 11px 0"
                  onClick={() => handleDeleteManager(i)}
                >
                  <CloseEditSvg />
                </IconContainer>
              )}
            </ManagerItem>
          ))}
          {
            <AddItem
              onClick={() =>
                setResponsible((state) => [...state, { id: '', title: '' }])
              }
              title="+ Додати відповідального"
            />
          }
        </Col>
      </InputsContainer>
      <FormBtns
        disabled={notAllFieldsAreFilled || isLoading}
        onCancel={() => setShow(false)}
      />
    </AddTaskStyled>
  );
};
