import type { Filter } from '../../common/types/filters';
import type { ReponseWithPagination } from '../../common/types/response-with-pagination';
import { NoWrap } from '../../components/no-wrap';
import { Status } from '../../components/status';
import { StringCutter } from '../../components/string-cutter';
import { concatenateAddress } from '../../helpers/concatenate-address';
import { dateTableFormat } from '../../helpers/date-helpers';
import { isFieldAllowed } from '../../helpers/is-field-allowed';
import type { Channel } from '../../models/channel';
import type { Contact, DynamicContact } from '../../models/contact';
import type { Project } from '../../models/project';
import type { User, UserMe } from '../../models/user';

export const transformcontactData = (data?: Contact[]): DynamicContact[] => {
  const filteredData = data?.filter((item) => item) || [];

  return (
    filteredData?.map((item) => ({
      ...item,
      created_at: dateTableFormat(item.created_at, true),
      birth_date: dateTableFormat(item?.birth_date || '', false) as string,
      age: item.age,
      address: concatenateAddress({
        country: item.liv_country,
        city: item.liv_city,
        region: item.liv_region,
        street: item.liv_street,
        building: item.liv_building,
        unit: item.liv_unit,
        apartment: item.liv_apartment
      }),
      reg_address: concatenateAddress({
        country: item.reg_country,
        city: item.reg_city,
        region: item.reg_region,
        street: item.reg_street,
        building: item.reg_building,
        unit: item.reg_unit,
        apartment: item.reg_apartment
      }),
      type:
        item.is_partner && item.is_volunteer
          ? 'Волонтер, Партнер'
          : item.is_partner
          ? 'Партнер'
          : item.is_volunteer
          ? 'Волонтер'
          : '-',
      satisfaction: item.satisfaction?.title || '',
      status: item.status ? (
        <Status isActive={item.status?.id === 1}>{item.status?.title}</Status>
      ) : (
        ''
      ),
      resp_managers: (
        <>
          {item.resp_managers?.map((manager, i) => (
            <div key={manager.id}>
              <p style={{ whiteSpace: 'nowrap' }}>
                {`${manager.last_name || ''} ${manager.first_name || ''}`}
                {item.resp_managers?.length &&
                  i !== item.resp_managers.length - 1 &&
                  ','}
              </p>
            </div>
          ))}
        </>
      ),
      gender: item.gender?.title || '',
      family_status: item.family_status?.title || '',
      employment: item.employment?.title || '',
      education: item.education?.title || '',
      children: item.children || [],
      categories: (
        <StringCutter lines="2">
          {item.categories?.map((item) => item.name).join(', ') || ''}
        </StringCutter>
      ),
      project: (
        <>
          {item.projects?.map((project) => (
            <NoWrap key={project.id}>{project.name}</NoWrap>
          ))}
        </>
      ),
      comment: (
        <StringCutter lines="2">{item.comment}</StringCutter>
      ) as unknown as string,
      channels: item.channels?.map((item) => item.name).join(', '),
      utm: item.utm_campaign
    })) || []
  );
};

export const setUsersFilter = (
  filter: Filter,
  users?: ReponseWithPagination<User>
) =>
  users
    ? {
        ...filter,
        checkList: users.results.map((item) => ({
          id: item.id,
          title: `${item.last_name || ''} ${item.first_name || ''} ${
            item.patronymic || ''
          }`,
          checked: false
        }))
      }
    : filter;

export const setProjectFilters = (
  filter: Filter,
  projects?: ReponseWithPagination<Project>
) =>
  projects
    ? {
        ...filter,
        checkList: projects.results.map((item) => ({
          id: item.id,
          title: item.name,
          sub: [
            ...item.events.map((event) => ({
              id: event.id,
              title: event.name,
              checked: false,
              label: 'Подія',
              name: 'events'
            })),
            {
              id: 'no_events',
              title: 'Подія не вказана',
              checked: false,
              name: 'events',
              label: 'Подія'
            }
          ],
          name: 'events',
          checked: false
        }))
      }
    : filter;

export const setChannelsFilters = (
  filter: Filter,
  channels: ReponseWithPagination<Channel>
) => ({
  ...filter,
  checkList: channels.results.map((item) => ({
    id: item.id,
    title: item.name,
    name: 'channels',
    checked: false
  }))
});

export const setCategoriesFilters = (
  filter: Filter,
  categories: ReponseWithPagination<{
    id: string | number;
    name: string;
    is_active: boolean;
  }>
) => ({
  ...filter,
  checkList: categories.results.map((item) => ({
    id: item.id,
    title: item.name,
    name: 'categories',
    checked: false
  }))
});

export const allowedFilters = (
  filters: Filter[],
  userMe: UserMe | undefined
): Filter[] => {
  const mockFields: Record<string, string> = {
    children_ages: 'children',
    children_gen: 'children'
  };

  return filters
    .map((item) => {
      if (item.type === 'sublist') {
        return {
          ...item,
          list: item.list.filter((item) =>
            isFieldAllowed(mockFields[item.name] || item.name, userMe)
          )
        };
      }

      return item;
    })
    .filter((item) => {
      switch (item.type) {
        case 'checkbox':
          if (item.isSingly) {
            return isFieldAllowed(item.checkList[0].id as string, userMe);
          }

          return isFieldAllowed(item.name, userMe);
        case 'select':
          return isFieldAllowed(item.name, userMe);
        case 'sublist':
          return !!item.list.length;

        default:
          return true;
      }
    });
};
