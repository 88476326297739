export enum ApiPath {
  ROOT = '/',
  AUTH = '/auth/jwt',
  AUTH_USER = '/auth/users/',
  USERS = '/users',
  USER_ME = '/auth/users/me/',
  CONTACTS = '/contacts',
  CONTACTS_ID_LIST = '/contacts/id_list',
  CONTACTS_EXPORT = '/contacts-export/',
  CONTACTS_IMPORT = '/contacts-import/',
  CONTACT_NOTE_DETAILS = '/contact-note-details',
  CONTACT_CALLS = '/contact-calls',
  CONTACT_MEETS = '/contact-meets',
  CONTACT_ACTIVITY = '/contact-activity',
  CONTACT_CHANNELS = '/contact-channels',
  CONTACT_CHANGES = '/contact-changes',
  CONTACTS_DELEGATE = '/contacts-delegate',
  PROJECTS = '/projects',
  PROJECTS_DELEGATE = '/projects-delegate',
  PROJECTS_ARCHIVED = '/projects-archived',
  EVENTS = '/events',
  EVENT_STEPS = '/event-steps',
  TAMPLATES = '/templates',
  PARTNERS = '/partners',
  PARTNERS_CITIES = '/partners-cities/',
  PARTNER_CALLS = '/partner-calls',
  PARTNER_MEETS = '/partner-meets',
  PARTNER_NOTES = '/partner-notes',
  PARTNER_CHANGES = '/partner-changes',
  DISCHARGE_USER = '/users/discharge-user',
  USER_ACTIVITI = 'users/user-activity',
  ADDRESS_BOOKS = 'addressbooks',
  CREATE_ADDRESS_BOOK = 'create-addressbook/',
  ADD_CONTACTS_TO_ADDRESSBOOK = 'addressbook-emails',
  CONTACT_LETTERS = 'contact-campaigns-links',
  CREATE_LETTER = 'send-mail/',
  CONTACT_BD_LETTERS = 'contact-letters',
  MAKE_CALL = 'make-call/',
  CALL_RECORD = 'call-record',
  CONTACT_CALLS_HISTORY = 'contact-calls-history',
  CONTACT_TAGS = 'contact-tags',
  CONTACT_CATEGORIES = 'contact-categories/',
  EVENT_REPORT_PDF = 'event-report-pdf',
  DEACTIVATE = 'deactivation',
  BULK_STEP_CHANGE = '/bulk-step-change/',
  DASHBOARD = '/dashboard',
  BULK_UPDATE_SATISFACTION = '/update-satisfaction/',
  VOLUNTEERS_CITIES = '/volunteers-cities/',
  TASKS = '/tasks',
  ALL_TASKS_NOTES = '/all-tasks-notes',
  TASK_CHANGES = '/task-changes',
  TASKS_NOTIFS = '/tasks-notifs',
  READ_ALL_NOTIFS = '/read-all-notifs/'
}

export enum DashboardApiPath {
  PROJECTS = '/projects',
  EVENTS = '/events',
  AUDIENCE = '/audience',
  PARTNERS = '/partners'
}

export enum AuthApiPath {
  REFRESH = '/refresh/',
  VERIFY = '/verify/',
  CREATE = '/create'
}

export enum UsersApiPath {
  FORGOT_PASSWORD = '/forgot-password',
  ALL_USERS = '/all-users/',
  ROLES = '/roles/',
  BULK_DELETE = '/bulk-delete'
}
