import styled from 'styled-components';
import { centerFlex } from '../../../style/helpers';

export const HeaderNotifStyled = styled.div`
  padding-right: 12px;
  height: 24px;
  display: flex;
  align-items: center;
  border-right: 1px solid ${(p) => p.theme.colors.grey.bg};
`;

export const HeaderNotifIcon = styled.div`
  position: relative;
  width: 18px;
  height: 18px;
  ${centerFlex}
  cursor: pointer;
  svg {
    display: block;
    width: 100%;
    height: auto;
  }
`;

export const Badge = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  top: -4px;
  right: 0;
  background: ${(p) => p.theme.colors.primary};
`;

export const MessageBadge = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  top: -2px;
  left: -4px;
  background: ${(p) => p.theme.colors.primary};
`;
