import type { Dispatch, SetStateAction } from 'react';
import { useState } from 'react';
import type { ServerFile } from '../../../../common/types/server-file';
import type { Note } from '../../../../models/note';
import { useGetUserMeQuery } from '../../../../api/user-api';
import { PageFiles } from '../../../../components/page-files';
import {
  TabContent,
  TabsStyled
} from '../../../contacts/current-contact/current-contact-tabs/style';
import { TabItem } from '../../../../components/tab-item';
import { Notes } from '../../../../components/notes';
import {
  useDeleteTaskNoteMutation,
  useEditTaskMutation,
  useEditTaskNoteMutation
} from '../../../../api/task-api';
import type { Task } from '../../../../models/task';
import { TaskChanges } from '../task-changes';
import { tabList } from './data';

interface CurrentTaskTabsProps {
  files?: ServerFile[];
  contactId: string | number;
  setShowNewNote: Dispatch<SetStateAction<boolean>>;
  notes: Note[];
}

export const CurrentTaskTabs = ({
  files,
  contactId,
  setShowNewNote,
  notes
}: CurrentTaskTabsProps) => {
  const { data: userMe } = useGetUserMeQuery('');
  const [currentTab, setCurrentTab] = useState('activity');
  const [editTask, { isLoading }] = useEditTaskMutation();
  const [editNote] = useEditTaskNoteMutation();
  const [deleteNote] = useDeleteTaskNoteMutation();

  if (!userMe) {
    return null;
  }

  const renderContent = () => {
    switch (currentTab) {
      case 'activity':
        return (
          <TaskChanges
            editNote={editNote}
            deleteNote={deleteNote}
            id={contactId}
          />
        );
      case 'note':
        return (
          <Notes
            notes={notes}
            editNote={editNote}
            deleteNote={deleteNote}
            setShowNewNote={setShowNewNote}
          />
        );
      case 'files':
        return (
          <PageFiles<Task>
            contactId={contactId}
            files={files}
            editTrigger={editTask}
            isLoading={isLoading}
            isDeleteLoading={isLoading}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <TabsStyled>
        {tabList.map((item, i) => (
          <TabItem
            onClick={setCurrentTab}
            $isActive={item.id === currentTab}
            {...item}
            key={i}
          />
        ))}
      </TabsStyled>
      <TabContent>{renderContent()}</TabContent>
    </>
  );
};
