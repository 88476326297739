import type { Dispatch, SetStateAction } from 'react';
import { useEffect, useState } from 'react';
import { Transition } from 'react-transition-group';
import { useNotification } from '../../../../hooks/use-notification';
import { useChangeFields } from '../../../../hooks/use-change-fields';
import type { OptionItem } from '../../../../common/types/option-item';
import {
  dateToApiFromInput,
  dateToUIFormat
} from '../../../../helpers/date-helpers';
import { useEditTaskMutation } from '../../../../api/task-api';
import { getApiError } from '../../../../helpers/get-api-error';
import { TaskForm } from '../../task-form';
import type { Task } from '../../../../models/task';
import {
  defaultStyle,
  duration,
  transitionStyles,
  defaultContentStyle,
  transitionContentStyles
} from '../transition';
import { CloseEditSvg } from '../../../../components/svg/close-edit-svg';
import { UserInfo } from '../../../../components/user-info';
import {
  Close,
  EditHeader,
  EditHeaderRight,
  EditTaskData,
  EditTaskStyled,
  FormWrapper,
  TaskId
} from './style';

interface EditTaskProps {
  edit: boolean;
  setEdit: Dispatch<SetStateAction<boolean>>;
  task: Task;
}

interface TextFields {
  name: string;
  description: string;
}

export const EditTask = ({ setEdit, task, edit }: EditTaskProps) => {
  const notification = useNotification();
  const [highPriority, setHighPriority] = useState(task.high_priority);
  const { fields, handleFieldsChange, updateFields } =
    useChangeFields<TextFields>({
      name: task.title,
      description: task.description
    });
  const [responsible, setResponsible] = useState<OptionItem[]>(
    task.resp_managers.map((item) => ({ id: item.id, title: item.full_name }))
  );
  const [project, setProject] = useState<OptionItem | null>(task.project);
  const [date, setDate] = useState(
    dateToUIFormat(task.deadline ? new Date(task?.deadline || '') : new Date())
  );

  const [editTask, { isLoading }] = useEditTaskMutation();

  useEffect(() => {
    updateFields({
      name: task.title,
      description: task.description
    }),
      setProject(task.project);
  }, [edit, updateFields, task]);

  const handleSubmit = () => {
    editTask({
      id: task.id,
      body: {
        resp_managers: Array.from(
          new Set(responsible.map((item) => item.id).filter((item) => item))
        ),
        title: fields.name,
        description: fields.description,
        high_priority: highPriority,
        deadline: dateToApiFromInput(date),
        project: project?.id
      }
    })
      .unwrap()
      .then(() => {
        notification({
          title: 'Завдання відредаговано',
          message: 'Ви успішно відредагували завдання',
          type: 'success'
        });
        setEdit(false);
      })
      .catch((err) => {
        notification({
          title: 'Помилка',
          message: getApiError(err),
          type: 'error'
        });
      });
  };

  return (
    <Transition
      in={edit && !!task}
      mountOnEnter
      unmountOnExit
      timeout={duration}
    >
      {(state) => (
        <EditTaskStyled
          style={{
            ...defaultStyle,
            ...transitionStyles[state]
          }}
        >
          <div
            style={{
              ...defaultContentStyle,
              ...transitionContentStyles[state],
              height: '100%'
            }}
          >
            <EditTaskData>
              <Close onClick={() => setEdit(false)}>
                <CloseEditSvg />
              </Close>
              <EditHeader>
                <UserInfo
                  isBig
                  lastName={task.title}
                  firstName=""
                  patronymic=""
                />
                <EditHeaderRight>
                  <TaskId>ID: {task.id}</TaskId>
                </EditHeaderRight>
              </EditHeader>
              <FormWrapper>
                <TaskForm
                  fields={fields}
                  handleFieldsChange={handleFieldsChange}
                  responsible={responsible}
                  setResponsible={setResponsible}
                  project={project}
                  setShow={setEdit}
                  highPriority={highPriority}
                  setHighPriority={setHighPriority}
                  date={date}
                  setDate={setDate}
                  isLoading={isLoading}
                  onSubmit={handleSubmit}
                  setProject={setProject}
                />
              </FormWrapper>
            </EditTaskData>
          </div>
        </EditTaskStyled>
      )}
    </Transition>
  );
};
