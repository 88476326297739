import type { Dispatch, FormEventHandler, SetStateAction } from 'react';
import { useEffect, useState } from 'react';
import { useChangeFields } from '../../../hooks/use-change-fields';
import type { CommonContact } from '../../../models/contact';
import type { OptionItem } from '../../../common/types/option-item';
import {
  useCreateContactMutation,
  useGetContactCategoriesQuery,
  useGetContactChannelsQuery,
  useGetContactTagsQuery
} from '../../../api/contact-api';
import { useNotification } from '../../../hooks/use-notification';
import { getApiError } from '../../../helpers/get-api-error';
import { ContactForm } from '../contact-form';
import { useGetProjectsQuery } from '../../../api/projects-api';
import type { Project } from '../../../models/project';
import { useGetUserMeQuery } from '../../../api/user-api';
import { checkIsManager } from '../../../helpers/is-manager';

interface AddContactProps {
  setShow: Dispatch<SetStateAction<boolean>>;
}

export const AddContact = ({ setShow }: AddContactProps) => {
  const notification = useNotification();
  const { data: userMe } = useGetUserMeQuery('');
  const [createContact, { isLoading }] = useCreateContactMutation();
  const { fields, handleFieldsChange, updateFields } =
    useChangeFields<CommonContact>({
      last_name: '',
      first_name: '',
      patronymic: '',
      birth_date: '',
      phone: '',
      email: '',
      passport: '',
      id_number: '',
      liv_country: '',
      liv_region: '',
      liv_city: '',
      liv_district: '',
      liv_street: '',
      liv_building: '',
      liv_unit: '',
      liv_apartment: '',
      reg_country: '',
      reg_region: '',
      reg_city: '',
      reg_district: '',
      reg_street: '',
      reg_building: '',
      reg_unit: '',
      reg_apartment: '',
      children_num: '',
      utm_source: '',
      utm_campaign: '',
      utm_content: '',
      utm_medium: '',
      utm_term: '',
      age: 0,
      comment: ''
    });
  const [gender, setGender] = useState<OptionItem | null>({
    id: '',
    title: ''
  });
  const [qualification, setQualification] = useState<OptionItem | null>({
    id: '',
    title: ''
  });
  const [status, setStatus] = useState<OptionItem | null>({
    id: '',
    title: ''
  });
  const [familyStatus, setFamilyStatus] = useState<OptionItem | null>({
    id: '',
    title: ''
  });
  const [education, setEducation] = useState<OptionItem | null>({
    id: '',
    title: ''
  });
  const [employment, setEmployment] = useState<OptionItem | null>({
    id: '',
    title: ''
  });
  const [respManagers, setRespManagers] = useState<OptionItem[] | null>([
    {
      id: '',
      title: ''
    }
  ]);
  const [childrenCount, setChildrenCount] = useState<OptionItem | null>({
    id: '',
    title: ''
  });
  const [childrenList, setChildrenList] = useState<
    { age: number; gender: OptionItem; birth_date: string | null }[] | null
  >([]);
  const [passportFiles, setPassportFiles] = useState<File[]>([]);
  const [innFiles, setInnFiles] = useState<File[]>([]);
  const [termSpouse, setTermSpouse] = useState('');
  const [spouse, setSpouse] = useState<OptionItem>({ id: '', title: '' });
  const [channels, setChannels] = useState<OptionItem[]>([]);
  const [tags, setTags] = useState<OptionItem[]>([]);
  const [categories, setCategories] = useState<OptionItem[]>([]);
  const { data: allChannels } = useGetContactChannelsQuery('');
  const { data: allTags } = useGetContactTagsQuery('');
  const { data: allCategories } = useGetContactCategoriesQuery('');
  const [projects, setProjects] = useState<OptionItem<Project>[]>([]);
  const [events, setEvents] = useState<OptionItem[]>([]);
  const { data: allProjects } = useGetProjectsQuery(
    { params: '?size=100' },
    {
      skip: !userMe
        ? true
        : userMe.is_superuser
        ? false
        : !userMe.role?.view_project
    }
  );

  const isManager = checkIsManager(userMe);

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    // if (!gender.id) {
    //   notification({
    //     type: 'error',
    //     title: 'Помилка',
    //     message: 'Вкажіть гендер'
    //   });

    //   return;
    // }
    // if (!respManagers.filter((item) => item.id).length && !isManager) {
    //   notification({
    //     type: 'error',
    //     title: 'Помилка',
    //     message: 'Вкажіть власника контакту'
    //   });

    //   return;
    // }
    // if (!qualification.id) {
    //   notification({
    //     type: 'error',
    //     title: 'Помилка',
    //     message: 'Вкажіть Кваліфікатор'
    //   });

    //   return;
    // }
    // if (!status.id) {
    //   notification({
    //     type: 'error',
    //     title: 'Помилка',
    //     message: 'Вкажіть Статус'
    //   });

    //   return;
    // }
    // if (!benefit.id) {
    //   notification({
    //     type: 'error',
    //     title: 'Помилка',
    //     message: 'Вкажіть пільгову групу'
    //   });

    //   return;
    // }
    // if (!familyStatus.id) {
    //   notification({
    //     type: 'error',
    //     title: 'Помилка',
    //     message: 'Вкажіть сімейний статус'
    //   });

    //   return;
    // }
    // if (!education.id) {
    //   notification({
    //     type: 'error',
    //     title: 'Помилка',
    //     message: 'Вкажіть  освіту'
    //   });

    //   return;
    // }
    // if (!employment.id) {
    //   notification({
    //     type: 'error',
    //     title: 'Помилка',
    //     message: 'Вкажіть діяльність'
    //   });

    //   return;
    // }
    const eneredData = Object.entries(fields).filter((item) => item[1]);
    const formData = new FormData();
    eneredData.forEach(([key, value]) => {
      if (key === 'birth_date') {
        formData.append(key, value.split('/').reverse().join('-'));

        return;
      }
      formData.append(key, value);
    });
    passportFiles.forEach((item) => {
      formData.append('passport_files', item);
    });
    passportFiles.forEach((item) => {
      formData.append('inn_files', item);
    });
    if (gender?.id.toString()) {
      formData.append('gender', `${gender.id}`);
    }
    if (isManager) {
      formData.append('resp_managers', JSON.stringify([userMe?.id]));
    } else if (respManagers) {
      formData.append(
        'resp_managers',
        JSON.stringify(
          respManagers.filter((item) => item.id).map((item) => item.id)
        )
      );
    }
    formData.append('education', `${education?.id || 0}`);
    formData.append('family_status', `${familyStatus?.id || 0}`);
    formData.append('status', `${status?.id || 0}`);
    formData.append('qualification', `${qualification?.id || 0}`);
    formData.append('employment', `${employment?.id || 0}`);
    formData.append('children_num', childrenCount?.title || '0');
    if (childrenList?.length) {
      formData.append(
        'children',
        JSON.stringify(
          childrenList.map((item) => ({
            gender: item.gender.id,
            birth_date: item.birth_date?.split('/').reverse().join('-') || null
          }))
        )
      );
    }
    if (spouse.id && familyStatus?.id === 2) {
      formData.append('spouse', `${spouse.id}`);
    }
    if (channels.filter((item) => item.checked).length) {
      formData.append(
        'channels',
        JSON.stringify(
          channels.filter((item) => item.checked).map((item) => item.id)
        )
      );
    } else {
      formData.append('channels', JSON.stringify([]));
    }

    if (tags.filter((item) => item.checked).length) {
      formData.append(
        'tags',
        JSON.stringify(
          tags.filter((item) => item.checked).map((item) => item.id)
        )
      );
    } else {
      formData.append('tags', JSON.stringify([]));
    }

    if (categories.filter((item) => item.checked).length) {
      formData.append(
        'categories',
        JSON.stringify(
          categories.filter((item) => item.checked).map((item) => item.id)
        )
      );
    } else {
      formData.append('categories', JSON.stringify([]));
    }

    if (projects.filter((item) => item.checked).length) {
      formData.append(
        'projects',
        JSON.stringify(
          projects.filter((item) => item.checked).map((item) => item.id)
        )
      );
    } else {
      formData.append('projects', JSON.stringify([]));
    }

    if (events.filter((item) => item.checked).length) {
      formData.append(
        'events',
        JSON.stringify(
          events.filter((item) => item.checked).map((item) => item.id)
        )
      );
    } else {
      formData.append('events', JSON.stringify([]));
    }

    createContact(formData)
      .unwrap()
      .then(() => {
        setShow(false);
        notification({
          type: 'success',
          title: 'Контакт додано!',
          message: 'Ви успішно створили новий контакт.'
        });
      })
      .catch((err) => {
        console.log(err);
        notification({
          type: 'error',
          title: 'Помилка',
          message: getApiError(err)
        });
      });
  };

  useEffect(() => {
    if (allChannels) {
      setChannels(
        allChannels.results.map((item) => ({
          id: item.id,
          title: item.name,
          checked: false
        }))
      );
    }
  }, [allChannels]);

  useEffect(() => {
    if (allTags) {
      setTags(
        allTags.results.map((item) => ({
          id: item.id,
          title: item.name,
          checked: false
        }))
      );
    }
  }, [allTags]);

  useEffect(() => {
    if (allCategories) {
      setCategories(
        allCategories.results
          .filter((item) => item.is_active)
          .map((item) => ({
            id: item.id,
            title: item.name,
            checked: false
          }))
      );
    }
  }, [allCategories]);

  useEffect(() => {
    if (allProjects) {
      setProjects(
        allProjects.results.map((item) => ({
          id: item.id,
          title: item.name,
          checked: false,
          restFields: item
        }))
      );
    }
  }, [allProjects]);

  return (
    <ContactForm
      fields={fields}
      handleFieldsChange={handleFieldsChange}
      gender={gender}
      setGender={setGender}
      familyStatus={familyStatus}
      setFamilyStatus={setFamilyStatus}
      education={education}
      setEducation={setEducation}
      employment={employment}
      setEmployment={setEmployment}
      childrenCount={childrenCount}
      setChildrenCount={setChildrenCount}
      childrenList={childrenList}
      setChildrenList={setChildrenList}
      isLoading={isLoading}
      setShow={setShow}
      onSubmit={handleSubmit}
      qualification={qualification}
      setQualification={setQualification}
      status={status}
      setStatus={setStatus}
      passportFiles={passportFiles}
      setPassportFiles={setPassportFiles}
      innFiles={innFiles}
      setInnFiles={setInnFiles}
      respManagers={respManagers}
      setRespManagers={setRespManagers}
      termSpouse={termSpouse}
      setTermSpouse={setTermSpouse}
      spouse={spouse}
      setSpouse={setSpouse}
      channels={channels}
      setChannels={setChannels}
      projects={projects}
      setProjects={setProjects}
      events={events}
      setEvents={setEvents}
      isManager={isManager}
      updateFields={updateFields}
      tags={tags}
      setTags={setTags}
      categories={categories}
      setCategories={setCategories}
    />
  );
};
