export enum ApiTag {
  USERS = 'Users',
  USER = 'User',
  ROLES = 'Roles',
  ROLE = 'Role',
  CONTACTS = 'Contacts',
  CONTACTS_ID_LIST = 'ContactsIdList',
  CONTACT = 'Contact',
  PROJECTS = 'Projects',
  PROJECT = 'Project',
  EVENTS = 'Events',
  EVENT = 'Event',
  PARTNERS = 'Partners',
  PARTNER = 'Partner',
  ADDRESS_BOOKS = 'AddressBooks',
  LETTER = 'letter',
  TASKS = 'Tasks',
  TASK = 'Task',
  TASKS_NOTIFS = 'TaskNotifs'
}
