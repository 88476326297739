import styled from 'styled-components';

export const AddTaskStyled = styled.form`
  padding-top: 20px;
`;

export const InputsContainer = styled.div`
  padding-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  align-items: start;
`;

export const ColTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 140%;
  color: ${(p) => p.theme.colors.blue.dark};
`;

export const Col = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
`;
