import type {
  CheckList,
  Filter,
  FilterCheckbox,
  FilterSelect,
  FilterSublist,
  FilterText,
  SelectList
} from '../../common/types/filters';
import type { OptionItem } from '../../common/types/option-item';

const handleTextSubfilters = ({
  subFilter,
  name,
  value
}: {
  subFilter: FilterSublist;
  name: string;
  value: string;
}) => ({
  ...subFilter,
  list: subFilter.list.map((item) =>
    item.name === name ? { ...item, value } : item
  )
});

const handleCheckSubFilters = ({
  subFilter,
  name,
  checked,
  id
}: {
  subFilter: FilterSublist;
  name: string;
  checked: boolean;
  id: string | number;
}) => ({
  ...subFilter,
  list: subFilter.list.map((item) =>
    item.name === name
      ? {
          ...item,
          checkList: (item as FilterCheckbox).checkList.map((checkListItem) =>
            checkListItem.id === id
              ? { ...checkListItem, checked }
              : checkListItem
          )
        }
      : item
  )
});

const handleSelectSubFilters = ({
  subFilter,
  name,
  value,
  id
}: {
  subFilter: FilterSublist;
  name: string;
  value: OptionItem;
  id: string | number;
}) => ({
  ...subFilter,
  list: subFilter.list.map((item) =>
    item.name === name
      ? {
          ...item,
          selectList: (item as FilterSelect).selectList.map((selectListItem) =>
            selectListItem.id === id
              ? { ...selectListItem, value }
              : selectListItem
          )
        }
      : item
  )
});

export const handleChangeCheckbox = ({
  filters,
  checked,
  id,
  currentCheckList,
  currentFilter,
  subId,
  subChecked
}: {
  filters: Filter[];
  currentFilter: FilterCheckbox;
  currentCheckList: CheckList;
  checked: boolean;
  id: string | number;
  subId?: string | number;
  subChecked?: boolean;
}) =>
  filters.map((item) =>
    item.type === 'sublist'
      ? handleCheckSubFilters({
          subFilter: item,
          name: currentFilter.name,
          checked,
          id
        })
      : item.name === currentFilter.name && item.type === 'checkbox'
      ? {
          ...item,
          checkList: item.checkList.map((checkbox) =>
            checkbox.id === currentCheckList.id
              ? {
                  ...checkbox,
                  checked,
                  sub: !checked
                    ? []
                    : checkbox.sub?.map((sub) =>
                        sub.id === subId
                          ? { ...sub, checked: !!subChecked }
                          : sub
                      ) || []
                }
              : checkbox
          )
        }
      : item
  );

export const handleChangeSelect = ({
  filters,
  currentFilter,
  currentSelectList,
  value,
  id
}: {
  filters: Filter[];
  currentFilter: FilterSelect;
  currentSelectList: SelectList;
  value: OptionItem;
  id: string | number;
}) =>
  filters.map((item) =>
    item.type === 'sublist'
      ? handleSelectSubFilters({
          subFilter: item,
          name: currentFilter.name,
          value,
          id
        })
      : item.name === currentFilter.name && item.type === 'select'
      ? {
          ...item,
          selectList: item.selectList.map((selectListItem) =>
            selectListItem.id === currentSelectList.id
              ? { ...selectListItem, value }
              : selectListItem
          )
        }
      : item
  );

export const handleChangeText = ({
  filters,
  currentFilter,
  value
}: {
  filters: Filter[];
  currentFilter: FilterText;
  value: string;
}) =>
  filters.map((item) =>
    item.type === 'sublist'
      ? handleTextSubfilters({
          subFilter: item,
          name: currentFilter.name,
          value
        })
      : item.name === currentFilter.name
      ? { ...item, value }
      : item
  );

export const flatFilter = (filters: Filter[]) =>
  filters.map((item) => (item.type === 'sublist' ? item.list : item)).flat();

export const transformFilterToObj = (filters: Filter[]) => {
  let tempArr: (string | number | boolean)[][] = [];

  flatFilter(filters).forEach((item) => {
    if (item.type === 'checkbox' && item.isSingly) {
      tempArr = [
        ...tempArr,
        ...item.checkList.map((checkListItem) => [
          checkListItem.id,
          checkListItem.checked
        ])
      ];

      return;
    }
    if (item.type === 'select') {
      tempArr = [
        ...tempArr,
        ...item.selectList.map((selectListItem) => [
          selectListItem.id,
          selectListItem.value.id
        ])
      ];

      return;
    }
    if (item.type === 'checkbox') {
      tempArr = [
        ...tempArr,
        [
          item.name,
          `${item.checkList
            .filter((el) => el.checked)
            .map((el) => el.id)
            .join(',')}`
        ]
      ];

      const setSubList = () => {
        let ids = '';
        item.checkList
          .filter((item) => item?.sub?.length)
          .forEach((item) => {
            ids +=
              ' ' +
              item.sub
                ?.filter((item) => item.checked)
                .map((item) => item.id)
                .join(' ');
          });
        if (ids.trim()) {
          return [
            item.checkList[0]?.name || '',
            ids.trim().split(' ').join(',')
          ];
        }

        return [];
      };
      if (setSubList().length) {
        tempArr = [...tempArr, setSubList()];
      }

      return;
    }

    tempArr = [...tempArr, [item.name, item.value]];
  });

  return Object.fromEntries(tempArr.filter((item) => item[1]));
};

export const setFiltersFromQueryString = (
  query: string,
  initialFilters: Filter[]
) => {
  const queryObj = query.split('&').map((item) => {
    const [key, value] = item.split('=');

    return { [key]: value };
  });

  const setUniFilters = (item: FilterCheckbox | FilterText | FilterSelect) => {
    const apliedFilter = queryObj.find((obj) => obj[item.name]);
    if (item.type === 'checkbox' && item.isSingly) {
      return {
        ...item,
        checkList: item.checkList.map((checkListItem) =>
          queryObj.find((item) => item[checkListItem.id])
            ? { ...checkListItem, checked: true }
            : checkListItem
        )
      };
    }
    if (item.type === 'select') {
      return {
        ...item,
        selectList: item.selectList.map((selectListItem) => {
          const apliedFilter = queryObj.find((item) => item[selectListItem.id]);

          if (apliedFilter) {
            const value = Object.values(apliedFilter)[0];

            return {
              ...selectListItem,
              value: {
                id: value,
                title:
                  selectListItem.options.find((item) => item.id === value)
                    ?.title || ''
              }
            };
          }

          return selectListItem;
        })
      };
    }
    if (apliedFilter) {
      if (item.type === 'text') {
        return { ...item, value: decodeURIComponent(apliedFilter[item.name]) };
      }
      if (item.type === 'checkbox') {
        const apliedSubFilter = queryObj.find(
          (obj) => obj[item.checkList[0]?.name || '']
        );

        return {
          ...item,
          checkList: item.checkList.map((check) =>
            apliedFilter[item.name].split('%2C').includes(`${check.id}`)
              ? {
                  ...check,
                  checked: true,
                  sub: check.sub?.map((subItem) =>
                    apliedSubFilter && apliedSubFilter[subItem.name]
                      ? apliedSubFilter[subItem.name]
                          .split('%2C')
                          .includes(`${subItem.id}`)
                        ? { ...subItem, checked: true }
                        : subItem
                      : subItem
                  )
                }
              : check
          )
        };
      }
    }

    return item;
  };

  return initialFilters.map((item) => {
    if (item.type === 'sublist') {
      return { ...item, list: item.list.map(setUniFilters) };
    } else {
      return setUniFilters(item);
    }
  });
};
